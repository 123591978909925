import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.03 5.057c3.695 0 6.89 2.046 9.52 6.065.231.354.231.817 0 1.17-2.63 4.02-5.825 6.065-9.52 6.065-3.696 0-6.89-2.045-9.52-6.064a1.074 1.074 0 0 1 0-1.171c2.63-4.02 5.824-6.065 9.52-6.065Zm0 2.091-.212.003c-2.668.072-5.017 1.51-7.094 4.363l-.139.193.095.134c2.045 2.834 4.354 4.29 6.97 4.416l.219.008.16.002c2.758 0 5.178-1.439 7.31-4.371l.134-.188-.094-.134c-2.044-2.834-4.353-4.29-6.97-4.417l-.218-.007-.162-.002Zm.117 1.33c-.212.245-.34.568-.34.922 0 .764.6 1.384 1.34 1.384.65 0 1.19-.476 1.314-1.109a3.28 3.28 0 0 1 .697 2.032c0 1.785-1.4 3.231-3.128 3.231-1.728 0-3.128-1.446-3.128-3.23 0-1.785 1.4-3.231 3.128-3.231.039 0 .078 0 .117.002Z" }, null, -1)
  ])))
}
export default { render: render }