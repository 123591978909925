/**
 * @see src/CamBundle/Message/Websocket/Record/RecordConversionProgress.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordConversionProgressC = t.type({
    type: t.literal(Type.CONVERSION_PROGRESS),
    id: UUID,
    videoId: UUID,
    progress: t.number,
});

export type RecordConversionProgress = t.TypeOf<typeof RecordConversionProgressC>;
