import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m10.055 11.652 3.747 3.747a1.06 1.06 0 11-1.498 1.499l-5.245-5.245 5.255-5.257a1.06 1.06 0 111.499 1.499l-3.758 3.757Z" }, null, -1)
  ])))
}
export default { render: render }