/**
 * @see src/CamBundle/Message/Websocket/Report/ReportCreated.php
 */

import * as t from 'io-ts';
import { Type } from './Type';

export const ReportCreatedC = t.type({
    type: t.literal(Type.CREATE),
    id: t.string,
});

export type ReportCreated = t.TypeOf<typeof ReportCreatedC>;
