import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M17.969 7.446c.512.512.512 1.26 0 1.772l-6.775 6.775-.127.119c-.536.465-1.068.459-1.646-.119l-3.387-3.387-.128-.145c-.383-.486-.342-1.158.128-1.628.512-.512 1.26-.512 1.772 0l2.502 2.501 5.87-5.872.163-.144c.486-.383 1.158-.342 1.628.128z" }, null, -1)
  ])))
}
export default { render: render }