/**
 * @see src/CamBundle/Message/Websocket/Report/ReportUpdated.php
 */

import * as t from 'io-ts';
import { Type } from './Type';

export const ReportUpdatedC = t.type({
    type: t.literal(Type.UPDATE),
    id: t.string,
});

export type ReportUpdated = t.TypeOf<typeof ReportUpdatedC>;
