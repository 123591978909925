import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';
import { date } from 'io-ts-types/date';
import fromEnum from '../io-ts/fromEnum';
import { UserC } from './User';
import { BaseRoomC } from './Room';
import { RecurrenceC } from './Recurrence';
import optional from '../io-ts/optional';

export enum Status {
    PENDING = 0,
    STARTED = 1,
    EXPIRED = 2,
}

export const ScheduleRecordingC = t.type({
    durationBeforeLastStart: t.number,
    paused: optional(t.boolean),
    duration: t.number,
});

export const ScheduleC = t.type({
    id: t.number,
    name: t.string,
    nextStartAt: t.union([DateFromISOString, date]),
    nextStopAt: t.union([DateFromISOString, date]),
    createdBy: UserC,
    room: BaseRoomC,
    recording: t.union([ScheduleRecordingC, t.null]),
    duration: t.number,
    recordingTime: t.string,
    recur: t.union([RecurrenceC, t.null]),
    status: fromEnum('Status', Status),
});

export type Schedule = t.TypeOf<typeof ScheduleC>;

export const IntervalC = t.intersection([
    t.type({
        scheduleId: t.number,
        startAt: t.union([DateFromISOString, date]),
        stopAt: t.union([DateFromISOString, date]),
        duration: t.number,
    }),
    t.partial({
        isInException: t.boolean,
        isIntersected: t.boolean,
        isOverlapped: t.boolean,
    }),
]);

export type Interval = t.TypeOf<typeof IntervalC>;

export type IntersectionDetails = {
    status: INTERSECTION_STATUS | null,
    duration?: number,
}

export const enum INTERSECTION_STATUS {
    IS_OVERLAPPED = 'isOverlapped',
    START_IS_OVERLAPPED = 'startIsOverlapped',
    END_IS_OVERLAPPED = 'endIsOverlapped',
    NO_OVERLAP_INTERSECTED = 'noOverlapIntersected'
}
