import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18.438 4.376C17.916 4.096 17.417 4 16.616 4H7.384l-.231.003c-.67.018-1.12.121-1.59.373-.508.27-.916.679-1.187 1.186C4.096 6.084 4 6.583 4 7.384v9.232l.003.231c.018.67.121 1.12.373 1.59.27.508.679.916 1.186 1.188.522.279 1.021.375 1.822.375h4.419A6 6 0 0 1 20 11.803V7.384l-.003-.231c-.018-.67-.121-1.12-.372-1.59a2.876 2.876 0 0 0-1.187-1.187ZM7.228 5.638l9.388-.002.156.002c.492.01.692.072.894.18.222.12.397.294.515.516.12.222.183.443.183 1.05v1.798L5.636 9.18V7.384l.002-.156c.01-.491.072-.692.18-.894a1.24 1.24 0 0 1 .516-.515c.202-.109.403-.17.894-.181ZM6 11.8a.8.8 0 0 1 .8-.8h3.4a.8.8 0 0 1 0 1.6H6.8a.8.8 0 0 1-.8-.8Zm0 2.8a.8.8 0 0 1 .8-.8h2.4a.8.8 0 0 1 0 1.6H6.8a.8.8 0 0 1-.8-.8Z" }, null, -1),
    _createElementVNode("path", { d: "M12.5 17c0-2.484 2.011-4.5 4.495-4.5A4.503 4.503 0 0 1 21.5 17c0 2.484-2.016 4.5-4.505 4.5A4.498 4.498 0 0 1 12.5 17Zm6.154 1.897a.449.449 0 0 0 .146-.731l-1.35-1.35V15.2a.451.451 0 0 0-.45-.45.451.451 0 0 0-.45.45V17a.45.45 0 0 0 .135.32l1.48 1.48a.449.449 0 0 0 .49.097Z" }, null, -1)
  ])))
}
export default { render: render }