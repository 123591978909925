<template>
    <ErrorPage v-if="showErrorPage" />
    <AppPage v-else />
</template>

<script lang="ts" setup>
import {
    computed, onBeforeUnmount, onErrorCaptured,
} from 'vue';
import { useRoute } from 'vue-router';
import { socket } from '../scripts/socket';
import AppPage from './views/Root/AppPage.vue';
import ErrorPage from './views/Root/ErrorPage.vue';
import { mediaQueriesModule } from './store';
import ROUTE_NAMES from './routes/routeNames';
import ErrorWithUserMessage from '../scripts/errors/ErrorWithUserMessage';
import { makeErrorToast } from './composables/toaster';

const route = useRoute();

if (socket.disconnected) {
    socket.connect();
}

mediaQueriesModule.init();

onBeforeUnmount(() => {
    if (socket.connected) {
        socket.disconnect();
    }
});

onErrorCaptured((error: unknown) => {
    if (error instanceof ErrorWithUserMessage) {
        makeErrorToast(error.message);
        return false;
    }

    return true;
});

const showErrorPage = computed(() => route.name === ROUTE_NAMES.ERROR_PAGE);

</script>
