import * as t from 'io-ts';
import { ObservableTypeC } from './ObservableType';

export const BaseRoomC = t.type({
    id: t.number,
    name: t.string,
    type: ObservableTypeC,
});

export const MotionDetectionC = t.type({
    enabled: t.boolean,
});

export const RoomC = t.intersection([
    BaseRoomC,
    t.type({
        hasRecording: t.boolean,
        hasPausedRecording: t.boolean,
        hasPreparedRecord: t.boolean,
        isLocked: t.boolean,
        motionDetection: t.union([MotionDetectionC, t.null]),
    }),
]);

export type BaseRoom = t.TypeOf<typeof BaseRoomC>;
export type Room = t.TypeOf<typeof RoomC>;
