import fromEnum from '../io-ts/fromEnum';

enum ObservableType {
    TYPE_ROOM = 'room',
    TYPE_VIEW = 'view',
    TYPE_STREAM = 'stream',
}

export default ObservableType;

export const ObservableTypeC = fromEnum('RecordType', ObservableType);
