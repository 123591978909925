/**
 * @see src/CamBundle/Message/Websocket/Schedule/ScheduleCreated.php
 */

import * as t from 'io-ts';
import { Type } from './Type';

export const ScheduleCreatedC = t.type({
    type: t.literal(Type.CREATE),
    id: t.number,
});

export type ScheduleCreated = t.TypeOf<typeof ScheduleCreatedC>;
