import { createApp } from 'vue';
import $ from 'jquery';
import Toast from 'vue-toastification';
import App from './App.vue';
import router from './router';
import store, { permissionsModule, userModule } from './store';
import '../styles/main.scss';
import '../scripts/class-component-hooks';
import AppApi from '../scripts/api/app/AppApi';
import { setTheme } from '../scripts/mixins/themeManager';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.jQuery = $;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.$ = $;

async function loadInitialData(): Promise<void> {
    try {
        const { all, user } = await AppApi.getPermissions();
        permissionsModule.setAllPermissions(all);
        userModule.setPermissions(user);
    } catch (e) {
        // do nothing
    }
}

let app;

loadInitialData().then(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    app = createApp(App);

    app.use(router);
    app.use(store);

    app.use(Toast);

    app.mount('#app');

    setTheme(userModule.theme);
});
