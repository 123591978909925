import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_DEVICES,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminRoomView = (): unknown => import('../../../views/admin/AdminDevice/AdminRoomView.vue');
export default [
    {
        path: 'room/new',
        name: ROUTE_NAMES.ADMIN_ROOM_NEW,
        component: AdminRoomView,
        meta,
    },
    {
        path: 'room/edit/:id',
        name: ROUTE_NAMES.ADMIN_ROOM_VIEW,
        component: AdminRoomView,
        meta,
    },
];
