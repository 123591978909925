import * as t from 'io-ts';
import fromEnum from '../../../../io-ts/fromEnum';

export enum HintType {
    WARNING = 'warning',
}

const HintTypeC = fromEnum('HintType', HintType);

export const HintC = t.type({
    type: HintTypeC,
    title: t.string,
    description: t.string,
});

export type Hint = t.TypeOf<typeof HintC>;
