import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.475 16.094c-.86 0-1.475.607-1.475 1.453 0 .797.545 1.38 1.326 1.447l.149.006h11.05c.86 0 1.475-.606 1.475-1.453 0-.797-.545-1.38-1.326-1.446l-.149-.007H6.475zm0-5.547C5.615 10.547 5 11.154 5 12c0 .797.545 1.38 1.326 1.447l.149.006h10.952c.859 0 1.474-.607 1.474-1.453 0-.797-.545-1.38-1.325-1.447l-.15-.006H6.475zm0-5.547C5.615 5 5 5.606 5 6.453c0 .797.545 1.38 1.326 1.446l.149.007h11.05c.86 0 1.475-.607 1.475-1.453 0-.797-.545-1.38-1.326-1.447L17.525 5H6.475z" }, null, -1)
  ])))
}
export default { render: render }