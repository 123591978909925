import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m15.941 3c-0.7462-1.6e-4 -1.4833 0.16474-2.1582 0.48286-0.675 0.31813-1.2712 0.7816-1.7458 1.3572s-0.816 1.249-0.9995 1.972c-0.1836 0.72298-0.2048 1.4776-0.0622 2.2098l-7.2216 7.48c-0.49082 0.5027-0.76176 1.1797-0.75322 1.882 0.00853 0.7023 0.29585 1.3725 0.79874 1.8631s1.1802 0.7614 1.8828 0.7529 1.3731-0.2957 1.864-0.7984l7.1541-7.2441c0.8405 0.2128 1.7215 0.2062 2.5586-0.0194 0.8372-0.2255 1.6022-0.6623 2.2218-1.2685 0.6195-0.6062 1.0728-1.3614 1.3163-2.1932 0.2434-0.83173 0.2689-1.712 0.074-2.5564-0.0227-0.09753-0.071-0.18723-0.14-0.25981-0.069-0.07259-0.1562-0.12542-0.2524-0.15303-0.0963-0.02762-0.1983-0.029-0.2953-0.00402s-0.1855 0.07542-0.2565 0.14611l-2.8616 2.8592-2.5762-2.5761 2.8606-2.8592c0.0707-0.07099 0.1211-0.15959 0.146-0.25662 0.025-0.09703 0.0235-0.19896-0.0043-0.29522-0.0277-0.09627-0.0807-0.18336-0.1535-0.25226-0.0727-0.0689-0.1626-0.11711-0.2602-0.13961-0.3724-0.08566-0.7532-0.129-1.1353-0.1292h-0.0011z" }, null, -1)
  ])))
}
export default { render: render }