import { useToast, TYPE } from 'vue-toastification';
import { Component } from 'vue';
import Toast from '../components/Toast/Toast.vue';
import CloseButton from '../components/Elements/Button/CloseButton.vue';

const toast = useToast();

const makeToast = (
    title: string,
    message: string,
    variant: TYPE,
) => {
    toast({
        component: Toast,
        props: {
            title,
            message,
        },
    }, {
        icon: false,
        type: variant,
        timeout: 5000,
        hideProgressBar: true,
        draggable: false,
        closeButton: CloseButton as unknown as Component,
    });
};

const makeErrorToast = (message: string, title?: string) => {
    makeToast(title ?? 'Error!', message, TYPE.ERROR);
};

const makeSuccessToast = (message: string, title?: string) => {
    makeToast(title ?? 'Success!', message, TYPE.SUCCESS);
};

const makeWarningToast = (message: string, title?: string) => {
    makeToast(title ?? 'Warning!', message, TYPE.WARNING);
};

export {
    makeErrorToast,
    makeSuccessToast,
    makeWarningToast,
};
