import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18.2222 4.77778H17.3333V3.88889C17.3333 3.39797 16.9354 3 16.4444 3V3C15.9535 3 15.5556 3.39797 15.5556 3.88889V4.77778H8.44444V3.88889C8.44444 3.39797 8.04648 3 7.55556 3V3C7.06464 3 6.66667 3.39797 6.66667 3.88889V4.77778H5.77778C4.79111 4.77778 4.00889 5.57778 4.00889 6.55556L4 19C4 19.4715 4.1873 19.9237 4.5207 20.2571C4.8541 20.5905 5.30628 20.7778 5.77778 20.7778H18.2222C19.2 20.7778 20 19.9778 20 19V6.55556C20 5.57778 19.2 4.77778 18.2222 4.77778ZM18.2222 19H5.77778V10.1111H18.2222V19ZM9.33333 13.6667H7.55556V11.8889H9.33333V13.6667ZM12.8889 13.6667H11.1111V11.8889H12.8889V13.6667ZM16.4444 13.6667H14.6667V11.8889H16.4444V13.6667ZM9.33333 17.2222H7.55556V15.4444H9.33333V17.2222ZM12.8889 17.2222H11.1111V15.4444H12.8889V17.2222ZM16.4444 17.2222H14.6667V15.4444H16.4444V17.2222Z" }, null, -1)
  ])))
}
export default { render: render }