<template>
    <a
        class="hamburger"
        @click.stop="showMenu"
    >
        <MenuIcon />
    </a>
</template>

<script lang="ts" setup>
import { menuModule } from '../../store';
import MenuIcon from '../../../images/hamburger.svg?component';

const showMenu = (): void => {
    menuModule.showMenu();
};
</script>

<style lang="scss" scoped>
.hamburger {
    display: block;

    width: 1.75rem;
    height: 1.75rem;

    cursor: pointer;
}
</style>
