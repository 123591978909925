import * as t from 'io-ts';
import { date, DateFromISOString } from 'io-ts-types';

export const RecurrenceC = t.type({
    id: t.number,
    frequency: t.string,
    frequencyString: t.string,
    endAt: t.union([DateFromISOString, date, t.null]),
    occurrences: t.union([t.number, t.null]),
    totalOccurrences: t.union([t.number, t.null]),
    range: t.string,
    weekly: t.union([t.array(t.string), t.null]),
});

export type Recurrence = t.TypeOf<typeof RecurrenceC>;
export enum Range {
    NO_END = 'no_end_date',
    OCCURRENCES = 'end_by_occurrences',
    DATE = 'end_by_date',
}
