import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "#28282D",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M17.824 6.882h-1.942v-.97c0-.583-.388-.97-.97-.97H9.088c-.582 0-.97.387-.97.97v.97H6.176v-.947c0-.55-.45-.994-.993-.994h.046a1 1 0 00-.994 1v11.59a1 1 0 00.994.998h-.046c.549 0 .993-.45.993-.993v-.948h1.942v.97c0 .583.388.971.97.971h5.824c.582 0 .97-.388.97-.97v-.97h1.942v.947c0 .549.45.993.993.993h-.046a1 1 0 00.994-.999V5.94a1 1 0 00-.994-.999h.046a.996.996 0 00-.993.994v.947zm-1.942 1.942h1.942v1.94h-1.942v-1.94zm-9.706 0h1.942v1.94H6.176v-1.94zm0 3.882h1.942v1.941H6.176v-1.941zm9.706 0h1.942v1.941h-1.942v-1.941z" }, null, -1)
  ])))
}
export default { render: render }