/**
 * @see src/CamBundle/Message/Websocket/Schedule/ScheduleDeleted.php
 */

import * as t from 'io-ts';
import { Type } from './Type';

export const ScheduleDeletedC = t.type({
    type: t.literal(Type.DELETE),
    id: t.number,
});

export type ScheduleDeleted = t.TypeOf<typeof ScheduleDeletedC>;
