import { RawField } from './types/RawField';
import { Field, PartialField } from './types/Field';

export default abstract class Converter<T extends RawField> {
    public abstract canConvert(field: RawField): field is T

    protected abstract convertPartially(field: T): PartialField | null

    public convert(field: T): Field | null {
        const partiallyConvertedField = this.convertPartially(field);
        if (partiallyConvertedField === null) {
            return null;
        }

        const { type, propertyOrder, name } = field;
        const errors = field.errors || [];
        const tooltip = field.tooltip ?? null;
        const hint = field.hint ?? null;
        const title = field.attr?.['data-mobile-title'] || field.title || null;
        const updateOnChange = field.attr?.['data-update-form-on-change'] || false;
        const updateDebounce = field.attr?.['data-update-form-debounce'] || false;
        const disabled = field.disabled || false;

        return {
            ...partiallyConvertedField,
            title,
            name,
            type,
            disabled,
            propertyOrder,
            errors,
            tooltip,
            hint,
            updateOnChange,
            updateDebounce,
        };
    }
}
