import fromEnum from '../../../io-ts/fromEnum';

export enum Status {
    BLOCKED = 'blocked',
    OVERLAP = 'overlap',
    INTERSECT = 'intersect',
    READY = 'ready',
}

export const StatusC = fromEnum('Status', Status);
