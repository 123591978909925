import { RecordMessageC } from '../../../scripts/types/sockets/record';
import { ReportMessageC } from '../../../scripts/types/sockets/report';
import { ScheduleMessageC } from '../../../scripts/types/sockets/schedule';
import { Channel, useTypedSocket } from '../../composables/socket';
import { sidebarModule } from '../../store';

type Props = {
    hideSidebar(): void,
}

type EntityMessage = {
    id: string|number;
    type: string;
}

export const useSidebarSocketLogic = ({
    hideSidebar,
}: Props) => {
    const handleSocketMessage = (
        entityType: string, { id: entityId, type }: EntityMessage,
    ) => {
        if (
            sidebarModule.entityId !== entityId
            || type !== 'delete'
            || sidebarModule.entityType !== entityType
        ) {
            return;
        }

        hideSidebar();
    };

    useTypedSocket(Channel.RECORD, RecordMessageC, (message) => handleSocketMessage('record', message));
    useTypedSocket(Channel.REPORT, ReportMessageC, (message) => handleSocketMessage('report', message));
    useTypedSocket(Channel.SCHEDULE, ScheduleMessageC, (message) => handleSocketMessage('schedule', message));
};
