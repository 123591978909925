import { ref, onMounted, onUnmounted } from 'vue';
import breakpoints from '../../styles/vendors/_breakpoints.module.scss?module';
import assertIsString from '../../scripts/typeAssertions/string';

export function useIsMobileDevice() {
    const isMobile = ref(false);

    assertIsString(breakpoints?.md);
    const MD_BREAKPOINT = parseInt(breakpoints.md.replace('px', ''), 10);

    const updateIsMobile = () => {
        isMobile.value = window.innerWidth <= MD_BREAKPOINT || navigator.maxTouchPoints > 0;
    };

    onMounted(() => {
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateIsMobile);
    });

    return { isMobile };
}
