import room from './room';
import camera from './camera';
import edgeCamera from './edgeCamera';
import device from './beam';
import stream from './stream';
import roam from './roam';
import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_DEVICE_LIST,
        component: () => import('../../../views/admin/AdminDevice/AdminDeviceList.vue'),
        meta: {
            title: SECTION_NAMES.ROOMS_AND_DEVICES,
            icon: CameraIcon,
            permissions: (): string[] => [pM.allPermissions.adminRooms],
        },
    },
    ...room,
    ...camera,
    ...edgeCamera,
    ...device,
    ...stream,
    ...roam,
];
