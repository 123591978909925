/**
 * @see src/CamBundle/Message/Websocket/Schedule/ScheduleUpdated.php
 */

import * as t from 'io-ts';
import { Type } from './Type';
import { ScheduleC } from '../../Schedule';

export const ScheduleUpdatedC = t.type({
    type: t.literal(Type.UPDATE),
    id: t.number,
    schedule: ScheduleC,
});

export type ScheduleUpdated = t.TypeOf<typeof ScheduleUpdatedC>;
