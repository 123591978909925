import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.284 13.909c3.924 0 5.79.95 6.676 2.332.36.56.598 1.413.715 2.6a1.27 1.27 0 01-1.139 1.389l-.125.006H3.387a1.271 1.271 0 01-1.262-1.406c.136-1.264.389-2.144.757-2.683.893-1.306 2.71-2.205 6.402-2.238zm6.258-2.274c3.363 0 4.963.815 5.722 1.999.308.48.513 1.212.613 2.23a1.088 1.088 0 01-.976 1.189l-.053.004h-2.999c-.16-.626-.378-1.158-.663-1.602-.834-1.3-2.174-2.167-4.1-2.621.334-.328.623-.7.86-1.107a15.15 15.15 0 011.596-.092zM9.39 5.275a3.81 3.81 0 11-.003 7.617 3.81 3.81 0 01.004-7.616zm6.243-1.04a3.265 3.265 0 11-1.184 6.308c.135-.463.206-.952.206-1.459a5.249 5.249 0 00-1.513-3.694 3.256 3.256 0 012.491-1.155z" }, null, -1)
  ])))
}
export default { render: render }