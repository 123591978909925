import * as t from 'io-ts';
import instanceOf from '../io-ts/instanceOf';

const NullableDateC = t.union([instanceOf(Date), t.null]);
const DatesTupleC = t.tuple([NullableDateC, NullableDateC]);

export type DatesTuple = t.TypeOf<typeof DatesTupleC>;

export enum TimeFormat {
    TWELVE_HOUR = 'twelve_hour',
    TWENTY_FOUR_HOUR = 'twenty_four_hour',
}
