<template>
    <div
        v-show="show"
        id="notification-container"
        ref="nofificationRef"
        class="notification-container"
    >
        <div
            class="notification"
            :class="{
                'notification--title-with-message': variant === 'title_with_message'
            }"
        >
            <CloseButton
                data-cy="close-notification-button"
                class="notification__close-button"
                @click="hide"
            />

            <Component
                :is="icon"
                v-if="icon"
                class="notification__icon"
            />

            <h3
                v-if="title"
                class="notification__title"
            >
                {{ title }}
            </h3>

            <div class="notification__text">
                {{ text }}
            </div>

            <div
                v-if="warning"
                class="notification__warning"
            >
                {{ warning }}
            </div>

            <div
                v-if="checkbox"
                class="notification__checkbox"
            >
                <Checkbox
                    :name="checkbox.label"
                    :aria-label="checkbox.label"
                    :model-value="checkbox.checked"
                    :cypress-id="`${checkbox.label}-checkbox-in-notification`"
                    @update:model-value="updateCheckboxChecked"
                >
                    {{ checkbox.label }}
                </Checkbox>
            </div>

            <div
                class="notification__buttons notification-buttons"
                :class="{
                    'notification-buttons--row': onlyTwoButtons
                }"
            >
                <Button
                    v-if="cancelButton"
                    class="notification__button"
                    :variant="cancelButton.variant || 'gray'"
                    @click="handleAction(cancelButton)"
                >
                    {{ cancelButton.text }}
                </Button>
                <Button
                    v-for="button in actionButtons"
                    :key="button.text"
                    :cypress-id="`${button.text}-button-in-notification`"
                    class="notification__button"
                    :variant="button.variant || 'dark'"
                    @click="handleAction(button)"
                >
                    {{ button.text }}
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ref, computed, onMounted, onBeforeUnmount, watch,
} from 'vue';
import { notificationModule } from '../store';
import Button from './Elements/Button.vue';
import CloseButton from './Elements/Button/CloseButton.vue';
import Checkbox from './Elements/Checkbox.vue';
import { NotificationButton } from '../../scripts/helpers/Notification';

const notificationRef = ref<HTMLElement | null>(null);

const show = computed(() => notificationModule.notification.show);

const variant = computed(() => notificationModule.notification.variant);

const title = computed(() => notificationModule.notification.title);

const text = computed(() => notificationModule.notification.text);

const warning = computed(() => notificationModule.notification.warning);

const actionButtons = computed(() => notificationModule.notification.actionButtons);

const cancelButton = computed(() => notificationModule.notification.cancelButton);

const checkbox = computed(() => notificationModule.notification.checkbox);

const icon = computed(() => notificationModule.notification.icon);

const onlyTwoButtons = computed(
    () => (
        cancelButton.value
            ? actionButtons.value.length === 1
            : actionButtons.value.length === 2
    ),
);

const updateCheckboxChecked = (newValue: boolean) => {
    if (checkbox.value) {
        checkbox.value.toggleCheckbox(newValue);
    }
};

const hide = () => {
    notificationModule.hideNotification();
};

const handleAction = (button: NotificationButton) => {
    button.callback();
    hide();
};

const onKeyDown = (event: KeyboardEvent) => {
    if (!show.value) return;
    if (event.key === 'Escape') hide();
};

onMounted(() => {
    window.addEventListener('keydown', onKeyDown);
});

onBeforeUnmount(() => {
    window.removeEventListener('keydown', onKeyDown);
});

watch(show, (newVal) => {
    if (!newVal) return;
    if (document.fullscreenElement) {
        document.fullscreenElement.appendChild(notificationRef.value!);
    }
});
</script>

<style lang="scss" scoped>
@import '../../styles/abstracts/z-indexes';
@import '../../styles/abstracts/font-sizes';
@import '../../styles/abstracts/spacings';
@import '../../styles/abstracts/variables';

.notification-container {
    position: fixed;
    inset: 0;

    z-index: $notification-container-z-index;

    display: flex;

    place-items: center;

    background-color: var(--theme-color-overlay-strong);
}

.notification {
    position: relative;

    display: flex;

    flex-direction: column;

    align-items: center;

    max-width: 27.625rem;

    padding: $spacing-xxl-2 $spacing-xxl $spacing-xxl;

    margin: 0 auto;

    text-align: center;

    background-color: var(--theme-color-surface-primary-default);
    border-radius: $border-radius-lg;

    &__close-button {
        position: absolute;
        top: $spacing-s;
        right: $spacing-s;
    }

    &__icon {
        width: $spacing-xxl-6;
        height: $spacing-xxl-6;
        padding: $spacing-xxs;
        margin-bottom: $spacing-m;

        background-color: var(--theme-color-surface-primary-default);
        border-radius: $border-radius-lg;
        box-shadow: var(--shadow-4);

        fill: currentColor;
    }

    &__text {
        font-size: $spacing-m;
        font-weight: bold;
        word-break: break-word;
    }

    &__warning {
        margin-top: $spacing-xs;

        white-space: pre-wrap;
    }

    &-buttons {
        display: grid;

        grid-gap: $spacing-m;
    }

    &-buttons--row {
        grid-template-columns: 1fr 1fr;
    }

    &__buttons {
        margin-top: $spacing-l;
    }

    &__button {
        width: 12rem;
        margin: 0 !important;
    }

    &__checkbox {
        margin-top: $spacing-l;

        label {
            display: flex;

            align-items: center;
            justify-content: center;
        }
    }
}

.notification--title-with-message {
    .notification__title {
        font-size: $font-size-lg;
        font-weight: 700;

    }

    .notification__text {
        font-size: $font-size-lg;
        font-weight: 400;
        word-break: break-word;
    }
}
</style>
