import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M19.53 8.941v9.316l-.002.135c-.008.36-.047.539-.116.692l-.026.053-.014.026a1.07 1.07 0 01-.444.445l-.053.026c-.149.072-.315.116-.65.127l-.134.003H5.979c-.453 0-.657-.04-.827-.117l-.053-.026-.027-.013a1.07 1.07 0 01-.444-.445L4.6 19.11c-.072-.149-.115-.315-.127-.65l-.003-.134V8.941h15.058zm-6.354.941h-2.352a.706.706 0 00-.042 1.411l.042.001h2.352a.706.706 0 00.042-1.41l-.042-.002zM5.038 4.235h13.924l.135.002c.36.007.54.047.693.116l.053.026.026.013c.191.103.342.253.444.445l.027.053c.077.159.121.337.13.718v1.019c-.008.36-.048.54-.117.692l-.026.053-.014.026a1.07 1.07 0 01-.444.445l-.053.027c-.167.08-.357.125-.784.13H5.038c-.453 0-.657-.04-.828-.118l-.053-.025-.026-.014a1.07 1.07 0 01-.444-.445l-.027-.052c-.081-.168-.126-.357-.13-.785v-.818c0-.452.04-.656.117-.827l.026-.053.014-.026a1.07 1.07 0 01.444-.445l.053-.026c.159-.077.337-.121.719-.13h.135z" }, null, -1)
  ])))
}
export default { render: render }