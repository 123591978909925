import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13.464 4.393c.445.257.815.626 1.072 1.072l5.071 8.784a2.928 2.928 0 01-2.536 4.392H6.928a2.927 2.927 0 01-2.536-4.392l5.072-8.784a2.93 2.93 0 014-1.072zm-1.471 9.114a.987.987 0 100 1.974.987.987 0 000-1.974zm0-4.936-.116.007a.99.99 0 00-.871.99v1.955c0 .559.442.997.987.997l.116-.007a.99.99 0 00.87-.99V9.568a.986.986 0 00-.986-.997z" }, null, -1)
  ])))
}
export default { render: render }