import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  fill: "#FFF"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M11.228 4.658a.625.625 0 0 1 .115.36v9.963a.614.614 0 0 1-.61.619.603.603 0 0 1-.356-.116l-2.42-1.768H7.07c-1.848 0-3.346-1.588-3.346-3.547v-.338c0-1.959 1.498-3.547 3.346-3.547h.887l2.42-1.768a.604.604 0 0 1 .851.142zm2.17.815a.603.603 0 0 1 .855-.115 5.836 5.836 0 0 1 2.27 4.617 5.836 5.836 0 0 1-2.27 4.617.603.603 0 0 1-.854-.115.625.625 0 0 1 .113-.868 4.592 4.592 0 0 0 1.793-3.634c0-1.434-.667-2.76-1.793-3.635a.625.625 0 0 1-.113-.867zm-1.015 2.063a.603.603 0 0 1 .854-.115 3.229 3.229 0 0 1 0 5.108.603.603 0 0 1-.854-.115.625.625 0 0 1 .113-.868c.489-.38.777-.953.777-1.571 0-.619-.288-1.192-.777-1.572a.625.625 0 0 1-.113-.867z" }, null, -1)
  ])))
}
export default { render: render }