import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M19.433 10.24h-.67c-.547 0-1.006-.47-1.006-1.03 0-.28.12-.537.33-.726l.433-.423a1.078 1.078 0 000-1.535l-.984-.973a1.121 1.121 0 00-1.544 0l-.414.414a1 1 0 01-.75.34c-.565 0-1.037-.459-1.037-1v-.674c0-.594-.482-1.104-1.082-1.104h-1.34c-.6 0-1.077.506-1.077 1.104v.67c0 .54-.472.998-1.037.998a.99.99 0 01-.732-.325l-.428-.423a1.087 1.087 0 00-.772-.308 1.12 1.12 0 00-.772.308l-.993.968a1.083 1.083 0 000 1.532l.415.413c.22.198.344.462.344.744 0 .563-.459 1.03-1.006 1.03h-.67c-.605 0-1.112.47-1.112 1.07v1.336c0 .594.507 1.07 1.112 1.07h.67c.547 0 1.006.47 1.006 1.03a.993.993 0 01-.344.743l-.415.41a1.081 1.081 0 000 1.53l.984.977c.194.198.481.308.772.308.291 0 .574-.114.772-.308l.428-.422a.981.981 0 01.733-.326c.564 0 1.036.458 1.036 1v.668c0 .594.477 1.105 1.081 1.105h1.341c.6 0 1.077-.506 1.077-1.105v-.669c0-.54.472-.999 1.037-.999.282 0 .547.124.75.34l.414.413a1.12 1.12 0 001.545 0l.983-.977a1.086 1.086 0 000-1.536l-.432-.422a.969.969 0 01-.33-.726c0-.563.458-1.03 1.005-1.03h.67c.6 0 1.03-.47 1.03-1.07v-1.34c.007-.599-.42-1.07-1.02-1.07zm-3.869 1.74c0 1.94-1.575 3.52-3.53 3.52-1.954 0-3.529-1.58-3.529-3.52s1.575-3.52 3.53-3.52c1.954 0 3.53 1.58 3.53 3.52z" }, null, -1)
  ])))
}
export default { render: render }