/**
 * @see src/CamBundle/Message/Websocket/Report/ReportDeleted.php
 */

import * as t from 'io-ts';
import { Type } from './Type';

export const ReportDeletedC = t.type({
    type: t.literal(Type.DELETE),
    id: t.string,
});

export type ReportDeleted = t.TypeOf<typeof ReportDeletedC>;
