<template>
    <div class="notification-popup-wrapper">
        <div
            class="notification-popup"
            :data-cy="cypressId"
        >
            <div
                class="notifications-popup-header"
                :class="{'exception-header--list': false}"
            >
                <span class="notifications-popup-header__text">
                    {{ title }}
                    <VolumeOffIcon
                        v-if="!notificationSoundEnabled"
                        class="icon"
                    />
                </span>
                <div class="notifications-popup-header__actions">
                    <Tooltip
                        id="notification-action-settings"
                        title="Settings"
                        no-min-width
                    >
                        <DropdownButton
                            id="notification-actions"
                            circle
                            has-only-icon
                            :shadowed="false"
                            :show-dropdown="showDropdown"
                            variant="light"
                            size="sm"
                            button-class="dots"
                            aria-label="Notification Settings"
                            @dropdown-show="dropdownShown"
                            @dropdown-hidden="dropdownHidden"
                            @click.stop.prevent="dropdownShown"
                        >
                            <MoreIcon />
                            <template #dropdown>
                                <DropdownButtonOption
                                    @click.stop="muteToggle"
                                >
                                    <Component
                                        :is="muteIcon"
                                        width="24"
                                        height="24"
                                    />
                                    <span>{{ muteText }}</span>
                                </DropdownButtonOption>
                                <DropdownButtonOption
                                    @click.stop="readAll"
                                >
                                    <OpenEyeIcon
                                        width="24"
                                        height="24"
                                    />
                                    <span>Read all</span>
                                </DropdownButtonOption>
                            </template>
                        </DropdownButton>
                    </Tooltip>
                    <Tooltip
                        id="notification-action-close"
                        title="Close"
                        no-min-width
                    >
                        <Button
                            circle
                            has-only-icon
                            variant="light"
                            size="sm"
                            aria-label="Hide Notifications Center"
                            @click="$emit('hideNotifications')"
                        >
                            <CloseIcon
                                width="16"
                                height="16"
                            />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <Scrollable
                class="scrollable-notifications-list"
                @scroll-reach-end="$emit('scrollReachEnd')"
            >
                <ul
                    v-if="notifications.length > 0"
                    class="notifications-list"
                >
                    <li
                        v-for="(notification, index) in sortedNotifications"
                        :key="index"
                    >
                        <Component
                            :is="notificationByType(notification.objectType)"
                            :notification="notification"
                            @reload-notifications="$emit('reloadNotifications')"
                        />
                    </li>
                </ul>
                <NoNotificationsPlaceholder
                    v-else
                    title="No unread notifications"
                />
            </Scrollable>
            <div class="notifications-footer">
                <Link @click="viewAll">
                    <span class="notifications-footer-view-all-button">
                        View all
                    </span>
                </Link>
            </div>
            <div class="notification-popup__arrow" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { cloneDeep } from 'lodash-es';
import { muteNotifications, readAll } from '../../../scripts/api/notification/NotificationApi';
import { userModule } from '../../store';
import { NotificationItem } from '../../../scripts/types/Notification';
import Tooltip from '../Elements/Tooltip.vue';
import DropdownButton from '../Elements/DropdownButton.vue';
import DropdownButtonOption from '../Elements/DropdownButtonOption.vue';
import Scrollable from '../Elements/Scrollable.vue';
import NoNotificationsPlaceholder from './NoNotificationsPlaceholder.vue';
import Button from '../Elements/Button.vue';
import Link from '../Elements/Link.vue';
import VolumeOffIcon from '../../../images/volume-off.svg?component';
import VolumeIcon from '../../../images/volume.svg?component';
import MoreIcon from '../../../images/dots-bold.svg?component';
import OpenEyeIcon from '../../../images/open-eye.svg?component';
import CloseIcon from '../../../images/x.svg?component';

const title = 'Notifications';

type Props = {
    id?: string | null,
    cypressId?: string | null,
    notifications: NotificationItem[],
};

const props = withDefaults(defineProps<Props>(), {
    id: null,
    cypressId: null,
});

type Emits = {
    (event: 'reloadNotifications'): void,
    (event: 'scrollReachEnd'): void,
    (event: 'hideNotifications'): void,
};

defineEmits<Emits>();

const showDropdown = ref(false);

const router = useRouter();

const notificationSoundEnabled = computed(() => userModule.isNotificationSoundEnabled);

const muteText = computed(() => (!notificationSoundEnabled.value ? 'Unmute' : 'Mute'));

const muteIcon = computed(() => (!notificationSoundEnabled.value ? VolumeIcon : VolumeOffIcon));

const sortedNotifications = computed(() => {
    const notificationsCopy = cloneDeep(props.notifications) as NotificationItem[];
    return notificationsCopy.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
});

const notificationTypes = {
    Sharing: () => import('./Messages/SharingNotificationMessage.vue'),
    Reply: () => import('./Messages/ReplyNotificationMessage.vue'),
    Tag: () => import('./Messages/TagNotificationMessage.vue'),
};

const notificationByType = (type: keyof typeof notificationTypes) => notificationTypes[type] || 'div';

const muteToggle = async () => {
    await muteNotifications();
    userModule.setNotificationSoundEnabled(!userModule.isNotificationSoundEnabled);
};

const viewAll = async () => {
    await readAll();
    await router.push({ name: 'notifications' });
};

const dropdownShown = () => {
    showDropdown.value = true;
};

const dropdownHidden = () => {
    showDropdown.value = false;
};
</script>

<style lang="scss" scoped>
@import '../../../styles/vendors/breakpoints';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';
@import '../../../styles/abstracts/colors_old';

$padding-desktop: $spacing-l;
$margin-desktop: $spacing-xs;

$popup-width-desktop: 20rem;
$popup-height-desktop: 32rem;

$popup-width-xl: 18rem;
$popup-height-xl: 26rem;

$popup-width-lg: 16.25rem;
$popup-height-lg: 22rem;

.dropdown-button-option {
    display: flex;

    gap: $spacing-s;

    align-items: center;

    font-size: $font-size-m;

    svg {
        fill: var(--theme-color-icon-primary);
    }

    &:hover {
        border-radius: $border-radius-xs;
    }
}

.notification-popup-wrapper {
    position: relative;

    height: 100%;
}

.notification-popup {
    position: absolute;

    bottom: $spacing-xxl-10;
    left: $spacing-s;

    z-index: $user-notification-popup-z-index;

    display: flex;

    flex-flow: column;

    justify-content: space-between;

    width: $popup-width-desktop;
    height: $popup-height-desktop;

    padding: $spacing-xs $spacing-xs 0 $spacing-xs;

    color: var(--theme-color-text-primary);

    background-color: var(--theme-color-surface-primary-default);
    border: 1px solid var(--theme-color-border-primary-grey);

    border-radius: $border-radius-lg;

    box-shadow: var(--shadow-4);

    @include media-breakpoint-down(xl) {
        width: $popup-width-xl;
        height: $popup-height-xl;
    }

    @include media-breakpoint-down(lg) {
        width: $popup-width-lg;
        height: $popup-height-lg;
    }

    &__arrow {
        /* see https://css-tricks.com/snippets/css/css-triangle/ */
        @include media-breakpoint-up(xs) {
            $height-to-width-ratio: 3.2;

            $tail-width: $spacing-xxs;
            $tail-height: $tail-width * $height-to-width-ratio;

            position: absolute;
            bottom: $margin-desktop + $padding-desktop;
            left: -($tail-width * 2);

            color: var(--theme-color-surface-primary-default);

            border-top: $spacing-xxxs solid transparent;
            border-right: $tail-width solid;
            border-bottom: $spacing-xxxs solid transparent;
            border-left: $tail-width solid transparent;
        }
    }
}

.notifications-popup-header {
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding: $spacing-xs $spacing-s;

    font-size: $font-size-lg;

    font-weight: 600;

    &__text {
        display: flex;

        gap: $spacing-xxs;

        align-items: center;

        svg {
            width: 1.25rem;
            height: 1.25rem;

            fill: var(--theme-color-light-shadow);
        }
    }

    &__actions {
        display: flex;

        gap: $spacing-xs;

        align-items: center;
    }
}

.scrollable-notifications-list {
    height: calc(100% - 6rem);
}

.notifications-footer {
    padding: $spacing-sm $spacing-xxl-10;

    margin: 0 $spacing-sm;

    text-align: center;

    border-top: 1px solid var(--theme-color-border-divider-secondary);

    .notifications-footer-view-all-button {
        color: var(--theme-color-text-link) !important;

        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.notifications-list {
    padding: 0;
    margin: 0;

    list-style: none;

    > li {
        padding: $spacing-s;

        cursor: pointer;
    }

    li:hover {
        background-color: var(--theme-color-surface-secondary-default);

        border-radius: $border-radius;
    }
}

</style>
