/* eslint-disable import/no-mutable-exports */
// Let is required for this hack

/* See https://github.com/championswimmer/vuex-module-decorators/issues/83#issuecomment-546929583
*
* This is the "store accessor":
* It initializes all the modules using a Vuex plugin (see store/index.ts)
* In here you import all your modules, call getModule on them to turn them
* into the actual stores, and then re-export them.
*/

import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import UserModule from './modules/user';
import PermissionsModule from './modules/permissions';
import AlertModule from './modules/alert';
import LoadingModule from './modules/loading';
import TimerModule from './modules/timer';
import MenuModule from './modules/menu';
import RoomMenuModule from './modules/roomMenu';
import NotificationModule from './modules/notification';
import OverlayModule from './modules/overlay';
import HeaderModule from './modules/header';
import IsAnonymousModule from './modules/isAnonymous';
import RecordModule from './modules/record';
import CaptionsModule from './modules/captions';
import { ExtractGetters } from '../../scripts/types/ExtractGetters';
import MediaQueriesModule from './modules/mediaQueries';
import SidebarModule from './modules/sidebar';
import GlobalSettingsModule from './modules/globalSettings';
import TimezoneModule from './modules/timezone';
import SyncModule from './modules/sync';
import ColorsModule from './modules/colors';

// Each store is the singleton instance of its module class
// Use these -- they have methods for state/getters/mutations/actions
// (result from getModule(...))
export let userModule: UserModule;
export let timezoneModule: TimezoneModule;
export let permissionsModule: PermissionsModule;
export let alertModule: AlertModule;
export let loadingModule: LoadingModule;
export let timerModule: TimerModule;
export let menuModule: MenuModule;
export let roomMenuModule: RoomMenuModule;
export let notificationModule: NotificationModule;
export let overlayModule: OverlayModule;
export let globalSettingsModule: GlobalSettingsModule;
/** @deprecated */
export let headerModule: HeaderModule;
export let isAnonymousModule: IsAnonymousModule;
export let recordModule: RecordModule;
export let captionsModule: CaptionsModule;
export let mediaQueriesModule: MediaQueriesModule;
export let sidebarModule: SidebarModule;
export let syncModule: SyncModule;
export let colorsModule: ColorsModule;

// initializer plugin: sets up state/getters/mutations/actions for each store
export function initializeStores(store: Store<never>): void {
    userModule = getModule(UserModule, store);
    timezoneModule = getModule(TimezoneModule, store);
    permissionsModule = getModule(PermissionsModule, store);
    alertModule = getModule(AlertModule, store);
    loadingModule = getModule(LoadingModule, store);
    timerModule = getModule(TimerModule, store);
    menuModule = getModule(MenuModule, store);
    roomMenuModule = getModule(RoomMenuModule, store);
    notificationModule = getModule(NotificationModule, store);
    overlayModule = getModule(OverlayModule, store);
    globalSettingsModule = getModule(GlobalSettingsModule, store);
    headerModule = getModule(HeaderModule, store);
    isAnonymousModule = getModule(IsAnonymousModule, store);
    recordModule = getModule(RecordModule, store);
    captionsModule = getModule(CaptionsModule, store);
    mediaQueriesModule = getModule(MediaQueriesModule, store);
    sidebarModule = getModule(SidebarModule, store);
    syncModule = getModule(SyncModule, store);
    colorsModule = getModule(ColorsModule, store);
}

// for use in 'modules' store init (see store/index.ts), so each module
// appears as an element of the root store's state.
// (This is required!)
export const modules = {
    user: UserModule,
    timezone: TimezoneModule,
    permissions: PermissionsModule,
    alert: AlertModule,
    loading: LoadingModule,
    timer: TimerModule,
    menu: MenuModule,
    roomMenu: RoomMenuModule,
    notification: NotificationModule,
    overlay: OverlayModule,
    globalSettings: GlobalSettingsModule,
    header: HeaderModule,
    isAnonymous: IsAnonymousModule,
    record: RecordModule,
    captions: CaptionsModule,
    mediaQueries: MediaQueriesModule,
    sidebar: SidebarModule,
    sync: SyncModule,
    colors: ColorsModule,
};

// When new module is typed with vuex module decorators just add it with |,
// e.g. ExtractGetters<LoadingModule> | ExtractGetters<XXXModule>
// getters that return a function are not extracted
export type StoreGetterFromTypedModules =
    ExtractGetters<LoadingModule>
    | ExtractGetters<MenuModule>
    | ExtractGetters<RoomMenuModule>
    | ExtractGetters<HeaderModule>
    | ExtractGetters<UserModule>
    | ExtractGetters<TimezoneModule>
    | ExtractGetters<PermissionsModule>
    | ExtractGetters<NotificationModule>
    | ExtractGetters<IsAnonymousModule>
    | ExtractGetters<OverlayModule>
    | ExtractGetters<GlobalSettingsModule>
    | ExtractGetters<TimerModule>
    | ExtractGetters<RecordModule>
    | ExtractGetters<CaptionsModule>
    | ExtractGetters<MediaQueriesModule>
    | ExtractGetters<SidebarModule>
    | ExtractGetters<AlertModule>
    | ExtractGetters<ColorsModule>
