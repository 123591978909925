import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_DEVICES,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminBeamDeviceView = (): unknown => import('../../../views/admin/AdminDevice/AdminBeamDeviceView.vue');

export default [
    {
        path: 'beam-device/new',
        name: ROUTE_NAMES.ADMIN_BEAM_DEVICE_NEW,
        component: AdminBeamDeviceView,
        meta: { new: true, ...meta },
    },
    {
        path: 'beam-device/:id',
        name: ROUTE_NAMES.ADMIN_BEAM_DEVICE_VIEW,
        component: AdminBeamDeviceView,
        meta: { new: false, ...meta },
    },
];
