import { Router } from 'vue-router';
import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import * as t from 'io-ts';
import { UUID } from 'io-ts-types';
import { Form, RawFormC } from '../../../scripts/helpers/schemaConverter/converters/types/Form';
import fromEnum from '../../../scripts/io-ts/fromEnum';
import { Variant } from '../../components/Elements/Button/Variant';
import optional from '../../../scripts/io-ts/optional';
import { FileFieldC } from '../../../scripts/helpers/schemaConverter/converters/functions/toFileUpload';
import { FormFieldC } from '../../../scripts/helpers/schemaConverter/converters/types/FormField';
import { FieldsRecord } from '../../../scripts/helpers/schemaConverter/converters/types/Field';

const StatusC = t.type({
    message: t.literal('success'),
    id: t.union([t.number, UUID, t.undefined]),
    title: optional(t.string),
});

export const MessageResponseC = t.type({
    title: t.string,
    message: t.string,
});

export const ResponseC = t.union([StatusC, RawFormC, MessageResponseC]);
export type Response = t.TypeOf<typeof ResponseC>;

export const ActionButtonC = t.type({
    title: t.string,
    variant: fromEnum('ButtonVariant', Variant),
});

export type ActionButton = t.TypeOf<typeof ActionButtonC>;

export type Sidebar = {
    title: string;
    content: Form;
    action: string;
    preAction?: string;
    update?: string;
    button?: ActionButton;
    validatable: boolean,
    openObserveOnClose?: boolean,
};

export type Event = {
    type: EventType;
    /** @deprecated make use composition instead */
    target: {
        $router: Router,
    };
}

export type ResponseEvent = Event & { response: Response };

export enum EventType {
    SAVE = 'save',
    CANCEL = 'cancel',
}

export type Listener = {
    type: EventType;
    callable: (event: Event) => void;
}

@Module({ name: 'SidebarModule' })
export default class SidebarModule extends VuexModule {
    private _sidebar: Sidebar|null = null;

    private _entityId: string|number|null = null;

    private _entityType: string|null = null;

    private _listeners: Listener[] = [];

    public get hasSidebar(): boolean {
        return this._sidebar !== null;
    }

    public get entityId(): string|number|null {
        return this._entityId;
    }

    public get entityType(): string|null {
        return this._entityType;
    }

    public get sidebar(): Readonly<Sidebar>|null {
        return this._sidebar;
    }

    private get listeners(): ReadonlyArray<Listener> {
        return this._listeners;
    }

    @Action
    public dispatch(event: Event): void {
        this.listeners
            .filter(({ type }) => event.type === type)
            .forEach(({ callable }) => callable(event));
    }

    @Mutation
    public createSidebar(sidebar: Sidebar): void {
        this._sidebar = sidebar;
    }

    @Mutation
    public setContent(content: Form): void {
        if (!this._sidebar) {
            throw new Error('Unable to update the content when no sidebar');
        }

        const prevContent = this._sidebar.content;

        const savePrevFiles = ({
            prevFields,
            newFields,
        }: {
            prevFields: FieldsRecord,
            newFields: FieldsRecord,
        }) => {
            Object.keys(newFields).forEach((key) => {
                if (FileFieldC.is(newFields[key])
                    && FileFieldC.is(prevFields[key])
                    && prevFields[key].file
                ) {
                    newFields[key].file = prevFields[key].file;
                }
                if (FormFieldC.is(prevFields[key])
                    && FormFieldC.is(newFields[key])
                ) {
                    savePrevFiles({
                        prevFields: prevFields[key].fields as FieldsRecord,
                        newFields: newFields[key].fields as FieldsRecord,
                    });
                }
            });
        };

        savePrevFiles({
            prevFields: prevContent.fields,
            newFields: content.fields,
        });

        this._sidebar.content = content;
    }

    @Mutation
    public setEntityId(entityId: number|string|null): void {
        this._entityId = entityId;
    }

     @Mutation
    public setEntityType(type: string|null): void {
        this._entityType = type;
    }

    @Mutation
     public on(listener: Listener): number {
         return this._listeners.push(listener);
     }

    @Mutation
    public clearSidebar(): void {
        this._entityId = null;
        this._sidebar = null;
        this._listeners = [];
    }
}
