import { createStore, Store } from 'vuex';
import { initializeStores, modules } from './store-accessor';

const initializer = (store: Store<never>): void => initializeStores(store);
export const plugins = [initializer];
export * from './store-accessor';

const store = createStore({
    plugins: plugins as any,
    modules,
});

export default store;
