import { globalSettingsModule, syncModule, timezoneModule } from '../../vue/store';
import { GlobalSettings } from '../../vue/store/modules/globalSettings';

export const setGlobalSettings = ({
    commentOverlaySize,
    dateFormat,
    streamingMethod,
    sync,
    time,
    timeFormat,
}: GlobalSettings) => {
    globalSettingsModule.setDateFormatByName(dateFormat);
    globalSettingsModule.setStreamingMethod(streamingMethod);
    globalSettingsModule.setCommentOverlayFontSize(commentOverlaySize);
    globalSettingsModule.setTimeFormat(timeFormat);

    timezoneModule.configureTime(time);
    syncModule.configureSync(sync);
};
