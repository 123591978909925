import { RouteRecord, RouteRecordRaw } from 'vue-router';
import adminChildren from './admin';
import observeChildren from './observe';
import scheduleRoutes from './schedule';
import reportsRoutes from './reports';
import ROUTE_NAMES, { HOME_ROUTE_PATH } from './routeNames';
import { permissionsModule as pM } from '../store';
import getFirstPermittedAdminRoute from '../../scripts/helpers/functions/permissions/getFirstPermittedAdminRoute';
import BellIcon from '../../images/bell.svg?component';
import notificationChildren from './notification';

export interface CustomRoute extends RouteRecord {
    meta: {
        title?: string,
        anonymous?: true,
        hideMenu?: true,
        permissions?: () => string[],
    }
}

const Login = () => import('../views/Login/Login.vue');
const RecordShowView = () => import('../views/RecordShowView/RecordShowView.vue');

const routeConfig: RouteRecordRaw[] = [
    {
        path: '/login',
        name: ROUTE_NAMES.LOGIN_MAIN,
        component: Login,
        meta: {
            title: 'Login',
            anonymous: true,
            hideMenu: true,
        },
    },
    {
        path: '/login/full',
        name: ROUTE_NAMES.LOGIN_FULL,
        component: Login,
        meta: {
            title: 'Login',
            anonymous: true,
            hideMenu: true,
        },
    },
    {
        path: '/login/saml',
        name: ROUTE_NAMES.LOGIN_SSO,
        component: Login,
        meta: {
            title: 'Login with SSO',
            anonymous: true,
            hideMenu: true,
        },
    },
    {
        path: HOME_ROUTE_PATH,
        name: ROUTE_NAMES.HOME,
        component: () => import('../views/Home.vue'),
        meta: {
            title: 'Home',
            permissions: (): string[] => [pM.allPermissions.generalHome],
        },
    },
    {
        path: '/live',
        name: ROUTE_NAMES.OBSERVE_MAIN,
        component: () => import('../views/Observe/Observe.vue'),
        props: (route) => ({
            selectedRoomId: parseInt(route.params.roomId as string, 10) || null,
            selectedCameraId: parseInt(route.params.cameraId as string, 10) || null,
            sequence: route.params.sequence,
        }),
        meta: {
            title: 'Observe',
            permissions: (): string[] => [pM.allPermissions.monitoring],
        },
        children: observeChildren,
    },
    {
        path: '/record',
        name: ROUTE_NAMES.REVIEW_MAIN,
        component: () => import('../views/Review/Review.vue'),
        meta: {
            permissions: (): string[] => [pM.allPermissions.review],
        },
        children: [
            {
                path: 'review/list',
                name: ROUTE_NAMES.REVIEW_LIST,
                components: { list: () => import('../views/Review/ReviewList.vue') },
                meta: {
                    title: 'Review',
                    permissions: (): string[] => [pM.allPermissions.review],
                },
            },
            {
                path: 'review/thumbnails',
                name: ROUTE_NAMES.REVIEW_THUMBNAILS,
                components: { thumbnails: () => import('../views/Review/ReviewThumbnails.vue') },
                meta: {
                    title: 'Review',
                    permissions: (): string[] => [pM.allPermissions.review],
                },
            },
        ],
    },
    {
        path: '/record/:recordId',
        name: ROUTE_NAMES.REVIEW_RECORD_SHOW,
        component: RecordShowView,
        meta: {
            title: 'Review',
        },
        children: [
            {
                path: 'videos/:videoId',
                name: ROUTE_NAMES.REVIEW_RECORD_SHOW_CAMERA,
                component: RecordShowView,
                meta: {
                    title: 'Review',
                },
                children: [
                    {
                        path: 'comments/:commentId',
                        name: ROUTE_NAMES.REVIEW_RECORD_SHOW_CAMERA_COMMENT,
                        component: RecordShowView,
                        meta: {
                            title: 'Review',
                        },
                    },
                ],
            },
            {
                path: 'comments/:commentId',
                name: ROUTE_NAMES.REVIEW_RECORD_SHOW_COMMENT,
                component: RecordShowView,
                meta: {
                    title: 'Review',
                },
            },
        ],
    },
    {
        path: '/record/share/:sequence',
        name: ROUTE_NAMES.REVIEW_RECORD_SHARED_SHOW,
        component: RecordShowView,
        meta: {
            title: 'Review',
            anonymous: true,
        },
        children: [
            {
                path: 'cameras/:cameraId',
                name: ROUTE_NAMES.REVIEW_RECORD_SHARED_SHOW_CAMERA,
                component: RecordShowView,
                meta: {
                    title: 'Review',
                    anonymous: true,
                },
                children: [
                    {
                        path: 'comments/:commentId',
                        name: ROUTE_NAMES.REVIEW_RECORD_SHARED_SHOW_CAMERA_COMMENT,
                        component: RecordShowView,
                        meta: {
                            title: 'Review',
                            anonymous: true,
                        },
                    },
                ],
            },
            {
                path: 'comments/:commentId',
                name: ROUTE_NAMES.REVIEW_RECORD_SHARED_SHOW_COMMENT,
                component: RecordShowView,
                meta: {
                    title: 'Review',
                    anonymous: true,
                },
            },
        ],
    },
    {
        path: '/admin',
        name: ROUTE_NAMES.ADMIN_MAIN,
        component: () => import('../views/Admin.vue'),
        redirect: (): { name: string } => (
            {
                name: getFirstPermittedAdminRoute(),
            }
        ),
        meta: {
            title: 'Admin',
            permissions: (): string[] => (
                [pM.allPermissions.admin]
            ),
        },
        children: adminChildren,
    },
    {
        path: '/user/edit',
        name: ROUTE_NAMES.USER_SETTINGS,
        component: () => import('../views/UserSettings.vue'),
        meta: {
            title: 'User settings',
            permissions: (): string[] => (
                [
                    pM.allPermissions.generalChangePassword,
                    pM.allPermissions.generalEditProfile,
                    pM.allPermissions.reviewZoom,
                ]
            ),
        },
    },
    {
        path: '/help',
        name: ROUTE_NAMES.HELP,
        component: () => import('../views/Help.vue'),
        meta: {
            title: 'Help',
            permissions: (): string[] => [],
        },
    },
    {
        path: '/notifications',
        name: ROUTE_NAMES.NOTIFICATIONS,
        component: () => import('../views/Notification/Notifications.vue'),
        redirect: { name: 'notificationsAll' },
        meta: {
            title: 'Notifications',
            icon: BellIcon,
            permissions: (): string[] => [],
        },
        children: notificationChildren,
    },
    {
        path: '/logout',
        name: ROUTE_NAMES.LOGOUT,
        component: () => null,
        beforeEnter: (): void => {
            localStorage.removeItem('permissions');
            window.location.replace('/logout');
        },
        meta: {
            permissions: (): string[] => [],
        },
    },
    {
        path: '/:catchAll(.*)',
        name: ROUTE_NAMES.ERROR_PAGE,
        component: () => import('../views/Root/ErrorPage.vue'),
        meta: {
            title: 'Error',
        },
    },
    ...scheduleRoutes,
    ...reportsRoutes,
];
export default routeConfig;
