import { isPlainObject } from 'lodash-es';

export type JsonObjectUpToTwoLevels = Record<string, Record<string, unknown> | string>;

export default (json: JsonObjectUpToTwoLevels): FormData => {
    const formData = new FormData();
    const appendObjectToForm = (
        jsonObject: Record<string, unknown>, prefix?: string,
    )
        : void => {
        Object.entries(jsonObject).forEach(([name, value]) => {
            const key = prefix ? `${prefix}[${name}]` : name;
            if (isPlainObject(value)) {
                appendObjectToForm(value as Record<string, unknown>, key);
            } else {
                formData.append(key, value as string);
            }
        });
    };
    appendObjectToForm(json);

    return formData;
};
