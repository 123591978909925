import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m12.87 3.783 7.612 5.95a1.301 1.301 0 01-.87 2.267H19v6.7a1.3 1.3 0 0 1-1.3 1.3h-2.9a.8.8 0 01-.8-.8v-3.6a.6.6 0 00-.503-.592L13.4 15h-2.8a.6.6 0 00-.592.503L10 15.6v3.6a.8.8 0 01-.8.8H6.3A1.3 1.3 0 015 18.7V12h-.612a1.3 1.3 0 01-.87-2.266l7.612-5.951a1.3 1.3 0 01 1.74 0z" }, null, -1)
  ])))
}
export default { render: render }