import * as t from 'io-ts';
import { ScheduleCreatedC } from './ScheduleCreated';
import { ScheduleStatusC } from './ScheduleStatus';
import { ScheduleUpdatedC } from './ScheduleUpdated';
import { ScheduleDeletedC } from './ScheduleDeleted';

export const ScheduleMessageC = t.union([
    ScheduleCreatedC,
    ScheduleStatusC,
    ScheduleUpdatedC,
    ScheduleDeletedC,
]);
export type ScheduleMessage = t.TypeOf<typeof ScheduleMessageC>;

export { Type } from './Type';
