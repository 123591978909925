import * as t from 'io-ts';
import { TemplateC } from './Template';

export const UserGroupC = t.intersection([
    t.type({
        id: t.number,
        name: t.string,
        isDeleted: t.boolean,
    }),
    t.partial({
        infoTemplate: TemplateC,
    }),
]);

export type UserGroup = t.TypeOf<typeof UserGroupC>;
