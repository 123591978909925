<template>
    <Popover
        v-model:show="showSync"
        :target="target"
        :title="name"
        :position="placement"
        :boundary-padding="boundaryPadding"
        :auto-fit="autoFit"
        :offset="offset"
        @shown="emit('shown')"
        @hidden="emit('hidden')"
    >
        <slot />
    </Popover>
</template>

<script lang="ts" setup>
import {
    computed,
} from 'vue';
import Popover from './Popover.vue';
import { Position } from '../../store/modules/globalSettings';

type Props = {
    cypressId: string | null,
    target: string,
    name: string|null,
    placement: Position,
    noOpacity: boolean,
    boundaryPadding: number,
    show?: boolean,
    autoFit?: boolean,
    offset?: number,
};

const props = withDefaults(defineProps<Props>(), {
    name: null,
    cypressId: null,
    placement: Position.BOTTOM_RIGHT,
    noOpacity: false,
    boundaryPadding: 50,
    show: false,
    offset: 0,
});

type Emits = {
    (event: 'shown'): void,
    (event: 'hidden'): void,
    (event: 'update:show', value: Props['show']): void,
};
const emit = defineEmits<Emits>();

const showSync = computed<Props['show']>({
    get: () => props.show,
    set: (value: Props['show']) => {
        emit('update:show', value);
    },
});

</script>
