import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.711 9.623a15.044 15.044 0 014.28-.047l.327.047 1.38.215c.498.077.861.501.87.997l-.002.079-.35 5.824c-.077.678-.753 1.204-1.582 1.244l-.1.002H9.495c-.841 0-1.546-.5-1.67-1.166l-.013-.08-.349-5.824c-.03-.503.307-.948.79-1.061l.077-.015 1.38-.215zm2.272-4.682.103.004a.755.755 0 01.65.58l.014.07.006.093V6.94l4.238.575a.75.75 0 01.636.594l.011.068.006.087-.007.093a.752.752 0 01-.685.642l-.072.004-.094-.007-.474-.062c-1.418-.175-2.843-.427-4.268-.429-1.427-.001-2.854.247-4.273.418l-.562.073a.755.755 0 01-.852-.639.748.748 0 01.485-.8l.069-.023.092-.019 4.238-.575V5.688c0-.32.205-.597.508-.704l.067-.02.087-.017.077-.006z" }, null, -1)
  ])))
}
export default { render: render }