import { RouteLocationRaw } from 'vue-router';
import router from '../../../../vue/router';
import { CustomRoute } from '../../../../vue/routes';
import { userModule } from '../../../../vue/store';

export default (location: RouteLocationRaw): boolean => {
    const { permissions: getPermissions } = router.resolve(location).meta as CustomRoute['meta'];

    if (!getPermissions) {
        return false;
    }

    const permissions = getPermissions();

    return permissions.length === 0 || permissions.some(userModule.hasPermission);
};
