import fromEnum from '../../../io-ts/fromEnum';

export enum Type {
    CREATE = 'create',
    STATUS = 'status',
    UPDATE = 'update',
    DELETE = 'delete',
}

export const TypeC = fromEnum('Type', Type);
