import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M14.2498 5.24996C14.2498 6.49258 13.2424 7.49992 11.9998 7.49992C10.7572 7.49992 9.74984 6.49258 9.74984 5.24996C9.74984 4.00734 10.7572 3 11.9998 3C13.2424 3 14.2498 4.00734 14.2498 5.24996ZM14.2496 11.9999C14.2496 13.2425 13.2422 14.2499 11.9996 14.2499C10.757 14.2499 9.74965 13.2425 9.74965 11.9999C9.74965 10.7573 10.757 9.74993 11.9996 9.74994C13.2422 9.74994 14.2496 10.7573 14.2496 11.9999ZM14.2496 18.7498C14.2496 19.9924 13.2422 20.9998 11.9996 20.9998C10.757 20.9998 9.74965 19.9924 9.74965 18.7498C9.74965 17.5072 10.757 16.4999 11.9996 16.4999C13.2422 16.4999 14.2496 17.5072 14.2496 18.7498Z"
    }, null, -1)
  ])))
}
export default { render: render }