import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  "fill-rule": "evenodd",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M15.0338 10.0579C16.7017 10.0579 18.0591 8.75149 18.0591 7.14614C18.0591 5.54079 16.7017 4.23438 15.0338 4.23438C13.3659 4.23438 12.0086 5.54079 12.0086 7.14614C12.0086 7.36258 12.0379 7.57223 12.0843 7.77605L8.8372 9.72888C8.3209 9.33385 7.67653 9.08732 6.96662 9.08732C5.29872 9.08732 3.94141 10.3937 3.94141 11.9991C3.94141 13.6044 5.29872 14.9108 6.96662 14.9108C7.67653 14.9108 8.3209 14.6643 8.8372 14.2693L12.0843 16.2221C12.0379 16.4259 12.0086 16.6356 12.0086 16.852C12.0086 18.4574 13.3659 19.7638 15.0338 19.7638C16.7017 19.7638 18.0591 18.4574 18.0591 16.852C18.0591 15.2467 16.7017 13.9403 15.0338 13.9403C14.3239 13.9403 13.6796 14.1868 13.1633 14.5818L9.9162 12.629C9.96258 12.4252 9.99183 12.2155 9.99183 11.9991C9.99183 11.7826 9.96258 11.573 9.9162 11.3692L13.1633 9.41635C13.6796 9.81138 14.3239 10.0579 15.0338 10.0579Z" }, null, -1)
  ])))
}
export default { render: render }