import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M17.49 6.51a1.088 1.088 0 010 1.54L13.54 12l3.95 3.95a1.088 1.088 0 010 1.54 1.088 1.088 0 01-1.54 0L12 13.54l-3.95 3.95a1.088 1.088 0 01-1.54 0 1.088 1.088 0 010-1.54L10.46 12 6.51 8.05a1.088 1.088 0 010-1.54 1.088 1.088 0 011.54 0L12 10.46l3.95-3.95a1.088 1.088 0 011.54 0z" }, null, -1)
  ])))
}
export default { render: render }