import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.204 2.004c2.82.108 5.008 2.516 5.008 5.337v2.46c.686.157 1.286.6 1.58 1.251.134.295.208.622.208.966v6.01c0 1.3-1.053 2.353-2.352 2.353H7.352A2.351 2.351 0 015 18.03v-6.011c0-.414.107-.802.296-1.14.315-.564.87-.94 1.492-1.08V7.215a5.22 5.22 0 015.416-5.211zm-.022 10.737a1.584 1.584 0 00-1.77 1.574c0 .482.214.911.555 1.2.218.193.34.471.34.762v.315a.693.693 0 101.385 0v-.315c0-.299.136-.574.36-.775.382-.338.6-.861.517-1.429a1.59 1.59 0 00-1.387-1.332zm-3.18-5.526v2.523H15V7.304c0-1.56-1.154-2.928-2.708-3.075a3.002 3.002 0 00-3.29 2.986z" }, null, -1)
  ])))
}
export default { render: render }