import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M16.372 7.868c.418.241.764.588 1.005 1.005l4.755 8.235a2.745 2.745 0 0 1-2.378 4.118h-9.509a2.745 2.745 0 0 1-2.377-4.118l4.754-8.235a2.745 2.745 0 0 1 3.75-1.005zm-1.379 8.544a.926.926 0 1 0 0 1.852.926.926 0 0 0 0-1.852zm0-4.626-.108.006a.929.929 0 0 0-.817.928v1.833c0 .524.414.934.925.934l.109-.006a.929.929 0 0 0 .817-.928V12.72a.925.925 0 0 0-.926-.934z",
      fill: "inherit",
      "fill-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }