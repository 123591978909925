import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "#28282D",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M6.87685 4.99943L12.8722 4.99829L13.3562 5.00292C14.4787 5.02646 14.9737 5.1425 15.4821 5.41437C15.9597 5.66978 16.3285 6.03862 16.5839 6.5162C16.8558 7.02455 16.9718 7.51961 16.9954 8.64209L17 9.12606V14.8705L16.9954 15.3545L16.9794 15.799C16.9355 16.6187 16.817 17.0447 16.5839 17.4804C16.3285 17.958 15.9597 18.3268 15.4821 18.5822C14.9737 18.8541 14.4787 18.9701 13.3562 18.9937L12.8722 18.9983H7.12777L6.6438 18.9937L6.19928 18.9777C5.3796 18.9338 4.95364 18.8152 4.51791 18.5822C4.04032 18.3268 3.67149 17.958 3.41608 17.4804C3.10796 16.9043 3 16.3452 3 14.8705V9.12606L3.00463 8.64209C3.02817 7.51961 3.14421 7.02455 3.41608 6.5162C3.67149 6.03862 4.04032 5.66978 4.51791 5.41437C5.06015 5.12438 5.58727 5.01168 6.87685 4.99943ZM20.0743 8.67828C20.7377 8.36459 21.5018 8.84849 21.5018 9.58231V14.4133C21.5018 15.1471 20.7377 15.631 20.0743 15.3173L18.5743 14.608C18.2247 14.4427 18.0018 14.0907 18.0018 13.704V10.2916C18.0018 9.9049 18.2247 9.55287 18.5743 9.38757L20.0743 8.67828Z"
    }, null, -1)
  ])))
}
export default { render: render }