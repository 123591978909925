/**
 * @see https://github.com/gcanti/io-ts/blob/master/index.md#the-idea
 * */

import * as t from 'io-ts';
import isEmpty from 'lodash-es/isEmpty';

export const emptyArray = new t.Type<[], [], unknown>(
    'emptyArray',
    (input: unknown): input is [] => Array.isArray(input) && input.length === 0,
    (input, context) => (Array.isArray(input) && input.length === 0
        ? t.success(input as [])
        : t.failure(input, context)),
    t.identity,
);

type EmptyRecord = Record<string, never>;

export const EmptyRecordFromArray = new t.Type<EmptyRecord, EmptyRecord, unknown>(
    'EmptyRecordFromArray',
    (input: unknown): input is EmptyRecord => isEmpty(input),
    (input, context) => {
        if (Array.isArray(input) && input.length === 0) {
            return t.success({} as EmptyRecord);
        }

        if (isEmpty(input)) {
            return t.success(input as EmptyRecord);
        }

        return t.failure(input, context);
    },
    t.identity,
);
