import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import OneOf from '../../../io-ts/OneOf';
import FIELD_RENDER_TYPES from '../../fieldRenderTypes';
import fromEnum from '../../../io-ts/fromEnum';
import { HintC } from '../converters/types/Hint';

export const ALLOWED_TYPES = [
    'file',
    'multi_files',
    'boolean',
    'array',
    'object',
    'radio_checkbox_tree',
    'transcripts_cards',
    'string',
    'integer',
    'hidden',
] as const;

const FieldNonRecursiveCommonPropsC = t.intersection([
    t.type({
        title: t.union([t.string, t.null]),
        name: t.string,
        type: OneOf(ALLOWED_TYPES),
        errors: t.array(t.string),
        tooltip: t.union([t.string, t.null]),
        hint: t.union([HintC, t.null]),
        disabled: t.boolean,
        updateOnChange: t.boolean,
        updateDebounce: t.boolean,
        renderAs: fromEnum('FIELD_RENDER_TYPES', FIELD_RENDER_TYPES),
    }),
    t.partial({
        propertyOrder: t.number,
        isRequired: t.boolean,
        value: t.unknown,
        show: t.boolean,
        maxLength: t.union([t.number, t.null, t.undefined]),
        labelAttr: t.union([t.record(t.string, t.unknown), t.null]),
    }),
],
'FieldNonRecursiveCommonProps');

const FieldRecursiveCommonPropsC = t.partial({
    fields: t.record(t.string, FieldNonRecursiveCommonPropsC),
},
'FieldRecursiveCommonProps');

export const FieldCommonPropsC = t.intersection([
    FieldNonRecursiveCommonPropsC,
    FieldRecursiveCommonPropsC,
],
'FieldCommonProps');

export type FieldCommonProps = TypeOf<typeof FieldCommonPropsC>;
