import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.353 16.943c0 .39.317.706.706.706H8c.39 0 .929-.223 1.205-.5l5.942-5.94a.71.71 0 000-.999l-1.355-1.355a.706.706 0 00-.998 0l-5.942 5.942c-.276.275-.5.816-.5 1.205v.94zm11.112-8.054a.623.623 0 000-.884l-1.468-1.469a.626.626 0 00-.885 0l-1.148 1.149 2.353 2.353 1.148-1.149z" }, null, -1)
  ])))
}
export default { render: render }