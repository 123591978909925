export const getScrollParent = (node: HTMLElement | null): HTMLElement | null => {
    let currentNode = node;

    while (currentNode) {
        const styles = getComputedStyle(currentNode);
        const isOverflowYScroll = styles.overflowY === 'auto' || styles.overflowY === 'scroll';
        const isBody = currentNode === document.body;
        const isNodeScrollable = (
            currentNode.scrollHeight > currentNode.clientHeight
        ) && isOverflowYScroll;

        if (isNodeScrollable || isBody) {
            return currentNode;
        }

        currentNode = currentNode.parentElement;
    }

    return null;
};

export interface ScrollToElementOptions {
    element: HTMLElement;
    isHorizontal?: boolean;
    alignCenter?: boolean;
}

export function scrollToElement(element: HTMLElement): void;
export function scrollToElement(options: ScrollToElementOptions): void;
export function scrollToElement(
    arg: HTMLElement | ScrollToElementOptions,
): void {
    let element: HTMLElement;
    let isHorizontal = false;
    let alignCenter = false;

    if ('element' in arg) {
        element = arg.element;
        isHorizontal = arg.isHorizontal ?? false;
        alignCenter = arg.alignCenter ?? false;
    } else {
        element = arg;
    }

    const scrollElement = getScrollParent(element);
    if (!scrollElement) {
        return;
    }

    const {
        offsetTop,
        offsetHeight,
        offsetLeft,
        offsetWidth,
    } = element;

    let offset = isHorizontal ? offsetLeft : offsetTop;

    if (alignCenter) {
        offset -= isHorizontal
            ? (scrollElement.clientWidth / 2) - (offsetWidth / 2)
            : (scrollElement.clientHeight / 2) - (offsetHeight / 2);
    } else {
        offset -= isHorizontal
            ? scrollElement.clientWidth - offsetWidth
            : scrollElement.clientHeight - offsetHeight;
    }

    scrollElement.scrollTo({
        [isHorizontal ? 'left' : 'top']: Math.max(offset, 0),
        behavior: 'smooth',
    });
}

const getScrollDimensions = (element: HTMLElement, isHorizontal: boolean) => ({
    scroll: isHorizontal ? element.scrollWidth : element.scrollHeight,
    client: isHorizontal ? element.clientWidth : element.clientHeight,
});

export const scrollToEnd = (element: HTMLElement, isHorizontal = false): void => {
    const scrollElement = getScrollParent(element);
    if (!scrollElement) {
        return;
    }

    const { scroll, client } = getScrollDimensions(scrollElement, isHorizontal);

    scrollElement.scrollTo({
        [isHorizontal ? 'left' : 'top']: scroll - client,
        behavior: 'smooth',
    });
};
