import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_DEVICES,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminRoamDeviceView = (): unknown => import('../../../views/admin/AdminDevice/AdminRoamDeviceView.vue');

export default [
    {
        path: 'camera/new/roam-device',
        name: ROUTE_NAMES.ADMIN_ROAM_DEVICE_NEW,
        component: AdminRoamDeviceView,
        meta: { new: true, ...meta },
    },
    {
        path: 'camera/edit/roam-device/:id',
        name: ROUTE_NAMES.ADMIN_ROAM_DEVICE_VIEW,
        component: AdminRoamDeviceView,
        meta: { new: false, ...meta },
    },
];
