import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import { DateFromUnixTime } from 'io-ts-types/DateFromUnixTime';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawRetentionRuleFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.intersection([
            t.type({
                'data-retention-rule': t.literal(true),
            }),
            t.partial({
                retentionRuleOutDate: t.union([t.null, DateFromUnixTime]),
                hasDefaultValue: t.boolean,
            }),
        ]),
    }),
]);

export const RetentionRuleFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.RETENTION_RULE),
        retentionRuleOutDate: t.union([t.null, DateFromUnixTime]),
        hasDefaultValue: t.boolean,
    }),
]);

export const RetentionRuleFieldC = t.intersection([RetentionRuleFieldPartialC, FieldCommonPropsC]);

export type RawRetentionRuleField = TypeOf<typeof RawRetentionRuleFieldC>
export type RetentionRuleFieldPartial = TypeOf<typeof RetentionRuleFieldPartialC>
export type RetentionRuleField = TypeOf<typeof RetentionRuleFieldC>

export default (field: RawRetentionRuleField): RetentionRuleFieldPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.RETENTION_RULE,
        retentionRuleOutDate: field.attr?.retentionRuleOutDate ?? null,
        hasDefaultValue: field.attr?.hasDefaultValue ?? false,
    });
};
