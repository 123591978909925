import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.535 4.941a.85.85 0 01 .836.861.852.852 0 01-.75.856l-.096.005H7.664c-.916 0-1.678.72-1.752 1.649l-.005.138v7.092c0 .934.702 1.713 1.613 1.789l.135.006h2.88a.85.85 0 01.836.86.852.852 0 01-.75.857l-.096.005H7.664c-1.827 0-3.335-1.472-3.424-3.342l-.005-.18V8.458c0-1.875 1.436-3.42 3.258-3.512l.176-.005h2.866zm3.69 1.566a1 1 0 01.675.262l4.911 4.493a1 1 0 010 1.476L14.9 17.23a1 1 0 01-1.675-.738v-1.267H8.84a.6.6 0 01-.6-.6V9.374a.6.6 0 01.6-.6h4.384V7.507a1 1 0 01 1-1z" }, null, -1)
  ])))
}
export default { render: render }