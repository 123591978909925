import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.22344 12.6203C6.21108 12.8367 6.19922 13.0443 6.18658 13.2409C6.18658 13.2409 4 14.8677 4 16.0413C4 17.2149 4.85623 18.1662 5.91244 18.1662H17.3871C18.4433 18.1662 19.2996 17.2149 19.2996 16.0413C19.2996 14.8677 17.113 13.2409 17.113 13.2409C17.1003 13.0443 17.0885 12.8367 17.0761 12.6203C16.8882 9.33147 16.5836 4 11.6498 4C6.71597 4 6.41135 9.33146 6.22344 12.6203ZM9.94957 19.1113V19.111H13.3495V19.1113C13.3495 20.1545 12.5884 21.0002 11.6495 21.0002C10.7107 21.0002 9.94957 20.1545 9.94957 19.1113Z" }, null, -1)
  ])))
}
export default { render: render }