import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "#28282D",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14.7614 17.9165C14.8554 17.8225 14.9082 17.6951 14.9082 17.5622C14.9082 17.2855 14.6838 17.0611 14.4071 17.0611H12.8909V12.8909H17.0611V14.4071C17.0611 14.54 17.1139 14.6674 17.2079 14.7614C17.4036 14.9571 17.7208 14.9571 17.9165 14.7614L20.3237 12.3542C20.5194 12.1585 20.5194 11.8413 20.3237 11.6456L17.9165 9.23837C17.8225 9.1444 17.6951 9.09161 17.5622 9.09161C17.2855 9.09161 17.0611 9.31595 17.0611 9.59268V11.1089H12.8909V6.93863H14.4071C14.54 6.93863 14.6674 6.88584 14.7614 6.79187C14.9571 6.5962 14.9571 6.27894 14.7614 6.08326L12.3542 3.67606C12.1585 3.48038 11.8413 3.48038 11.6456 3.67606L9.23837 6.08326C9.1444 6.17723 9.09161 6.30468 9.09161 6.43757C9.09161 6.7143 9.31595 6.93863 9.59268 6.93863H11.1089L11.1089 11.1089H6.93863V9.59268C6.93863 9.45979 6.88584 9.33234 6.79187 9.23837C6.5962 9.04269 6.27894 9.04269 6.08326 9.23837L3.67606 11.6456C3.48038 11.8413 3.48038 12.1585 3.67606 12.3542L6.08326 14.7614C6.17723 14.8554 6.30468 14.9082 6.43757 14.9082C6.7143 14.9082 6.93863 14.6838 6.93863 14.4071V12.8909H11.1089V17.0611H9.59268C9.45979 17.0611 9.33234 17.1139 9.23837 17.2079C9.04269 17.4036 9.04269 17.7208 9.23837 17.9165L11.6456 20.3237C11.8413 20.5194 12.1585 20.5194 12.3542 20.3237L14.7614 17.9165Z" }, null, -1)
  ])))
}
export default { render: render }