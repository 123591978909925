<template>
    <div
        class="notifications"
        :class="{ 'notifications__active': isIconActive }"
    >
        <Button
            :id="id"
            :cypress-id="cypressId"
            has-only-icon
            size="sm"
            popover
            no-resize-icon
            class="notifications-button"
            aria-label="Notifications Center"
            @click="$emit('notificationsToggle')"
        >
            <BellIcon />
            <div
                v-if="hasNewNotification"
                class="notifications-button__dot"
                data-cy="user-info-notification-dot"
            />
        </Button>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import BellIcon from '../../../images/bell.svg?component';
import Button from '../Elements/Button.vue';
import ROUTE_NAMES from '../../routes/routeNames';

const route = useRoute();

type Props = {
    id?: string,
    cypressId?: string | null,
    hasNewNotification?: boolean,
    isActive?: boolean,
};

const props = withDefaults(defineProps<Props>(), {
    cypressId: null,
    isActive: false,
});

type Emits = {
    (event: 'notificationsToggle'): void,
};

defineEmits<Emits>();

const isIconActive = computed((): boolean => {
    const routeMatched = route.matched
        .some(({ name }) => ROUTE_NAMES.NOTIFICATIONS === name);
    return props.isActive || routeMatched;
});
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/spacings';

.notifications {
    display: flex;

    justify-content: center;

    margin: $spacing-xxs $spacing-xxs $spacing-m $spacing-xxs;

    color: var(--theme-color-icon-primary) !important;

    cursor: pointer;

    border-radius: $border-radius-sm;

    svg {
        width: 1.5rem;
        height: 1.5rem;

        fill: var(--theme-color-icon-inversed-secondary);
    }

    &__active,
    &:hover {
        background-color: var(--theme-color-surface-secondary-default);

        svg {
            fill: var(--theme-color-icon-inversed-secondary) !important;
        }
    }
}

.notifications-button {
    position: relative;

    color: inherit !important;

    background-color: transparent;

    &.btn {
        width: 3.25rem;
        height: 3.25rem;
    }

    &.btn:not(:disabled):not(.disabled):active {
        box-shadow: none;
    }

    &.btn:not(:disabled):not(.disabled):hover {
        background-color: transparent;
    }

    &__dot {
        @include notification-dot($spacing-xs, var(--theme-color-surface-menu));

        top: $spacing-xs;
        right: $spacing-s;
    }
}
</style>
