import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  fill: "#FFF"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m4.25 3.487 8.151 8.15.278.279 2.998 2.997a.72.72 0 1 1-1.018 1.019l-3.317-3.317v2.366a.614.614 0 0 1-.609.619.603.603 0 0 1-.356-.116l-2.42-1.768H7.07c-1.848 0-3.346-1.588-3.346-3.547v-.338c0-1.337.698-2.501 1.728-3.105l-2.22-2.221A.72.72 0 1 1 4.25 3.487zm8.987 3.934a3.229 3.229 0 0 1 1.007 3.795l-.987-.987a1.977 1.977 0 0 0-.76-1.826.625.625 0 0 1-.114-.867.603.603 0 0 1 .854-.115zm-2.009-2.763a.625.625 0 0 1 .115.36v3.298L8.739 5.712l1.638-1.196a.604.604 0 0 1 .851.142z",
      "fill-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }