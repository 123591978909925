import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M17.647 15.53a2.117 2.117 0 110 4.234H6.353a2.117 2.117 0 110-4.235h11.294zm-.147.74a1.36 1.36 0 10.001 2.717 1.36 1.36 0 00-.002-2.717zm.147-6.388a2.117 2.117 0 110 4.235H6.353a2.117 2.117 0 110-4.235h11.294zm-.147.76a1.36 1.36 0 10.002 2.72 1.36 1.36 0 00-.003-2.72zm.147-6.407a2.117 2.117 0 110 4.235H6.353a2.117 2.117 0 110-4.235h11.294zm-.147.776a1.36 1.36 0 10.001 2.717A1.36 1.36 0 0017.5 5.01z" }, null, -1)
  ])))
}
export default { render: render }