import userAndGroupChildren from './user-and-groups';
import templateChildren from './template';
import roomsAndCamerasChildren from './rooms-and-devices';
import containerChildren from './container';
import serversChildren from './servers';
import logChildren from './log';
import helpChildren from './help';
import GeneralSectionIcon from '../../../images/wrench.svg?component';
import PeopleIcon from '../../../images/people.svg?component';
import LinesIcon from '../../../images/lines.svg?component';
import CameraIcon from '../../../images/camera.svg?component';
import AlertIcon from '../../../images/alert-triangle.svg?component';
import ContainerIcon from '../../../images/container.svg?component';
import ServerIcon from '../../../images/server.svg?component';
import CalendarIcon from '../../../images/calendar.svg?component';
import QuestionBubbleIcon from '../../../images/question-bubble.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';
import getFirstPermittedServersRoute from '../../../scripts/helpers/functions/permissions/getFirstPermittedServersRoute';

export default [
    {
        path: 'general',
        name: ROUTE_NAMES.ADMIN_GENERAL,
        component: (): Promise<unknown> => import('../../views/admin/AdminGeneral.vue'),
        meta: {
            title: SECTION_NAMES.GENERAL,
            icon: GeneralSectionIcon,
            permissions: (): string[] => [
                pM.allPermissions.adminSoftwareInformation,
                pM.allPermissions.adminGeneral,
                pM.allPermissions.adminGlobalSettings,
                pM.allPermissions.adminMediaServers,
                pM.allPermissions.adminCustomization,
            ],
        },
    },
    {
        path: 'users-and-groups',
        name: ROUTE_NAMES.ADMIN_USER,
        redirect: { name: 'adminGroupList' },
        meta: {
            title: SECTION_NAMES.USERS_AND_GROUPS,
            icon: PeopleIcon,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
        children: userAndGroupChildren,
    },
    {
        path: 'template',
        name: ROUTE_NAMES.ADMIN_TEMPLATE,
        redirect: { name: 'adminTemplateList' },
        meta: {
            title: SECTION_NAMES.TEMPLATES,
            icon: LinesIcon,
            permissions: (): string[] => [pM.allPermissions.adminTemplates],
        },
        children: templateChildren,
    },
    {
        path: 'rooms-and-devices',
        name: ROUTE_NAMES.ADMIN_DEVICE,
        redirect: { name: ROUTE_NAMES.ADMIN_DEVICE_LIST },
        meta: {
            title: SECTION_NAMES.ROOMS_AND_DEVICES,
            icon: CameraIcon,
            permissions: (): string[] => [pM.allPermissions.adminRooms],
        },
        children: roomsAndCamerasChildren,
    },
    {
        path: 'containers',
        name: ROUTE_NAMES.ADMIN_CONTAINER,
        redirect: { name: 'adminContainerList' },
        meta: {
            title: SECTION_NAMES.CONTAINERS,
            icon: ContainerIcon,
            permissions: (): string[] => [pM.allPermissions.adminContainers],
        },
        children: containerChildren,
    },
    {
        path: 'servers',
        name: ROUTE_NAMES.ADMIN_SERVERS,
        redirect: () => ({
            name: getFirstPermittedServersRoute(),
        }),
        meta: {
            title: SECTION_NAMES.SERVERS_AND_SERVICES,
            icon: ServerIcon,
            permissions: (): string[] => [
                pM.allPermissions.adminServersWowza,
                pM.allPermissions.adminServersTranscriptionService,
                pM.allPermissions.adminServersLdapServers,
                pM.allPermissions.adminServersSsoConfigs,
            ],
        },
        children: serversChildren,
    },
    {
        path: 'logs',
        name: ROUTE_NAMES.ADMIN_LOG_MAIN,
        redirect: { name: ROUTE_NAMES.ADMIN_LOG_LOGIN },
        meta: {
            title: SECTION_NAMES.LOGS,
            icon: CalendarIcon,
            permissions: (): string[] => [pM.allPermissions.adminLogs],
        },
        children: logChildren,
    },
    {
        path: 'alerts',
        name: ROUTE_NAMES.ALERTS,
        component: (): Promise<unknown> => import('../../views/admin/AdminAlerts.vue'),
        meta: {
            title: SECTION_NAMES.ALERTS,
            icon: AlertIcon,
            permissions: (): string[] => [pM.allPermissions.generalAlert],
        },
    },
    {
        path: 'help',
        name: ROUTE_NAMES.ADMIN_HELP,
        redirect: { name: 'adminHelpList' },
        meta: {
            title: SECTION_NAMES.HELP,
            icon: QuestionBubbleIcon,
            permissions: (): string[] => [pM.allPermissions.adminHelp],
        },
        children: helpChildren,
    },
];
