import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M15.168 4c1.22 0 1.924.14 2.66.544a4.022 4.022 0 011.642 1.684c.394.756.53 1.479.53 2.73v6.084c0 1.251-.136 1.974-.53 2.73a4.028 4.028 0 01-1.642 1.684c-.736.404-1.44.544-2.66.544H8.832c-1.22 0-1.924-.14-2.66-.544a4.022 4.022 0 01-1.642-1.684c-.375-.72-.516-1.41-.53-2.57V8.959c0-1.251.136-1.974.53-2.73a4.028 4.028 0 011.642-1.684c.7-.385 1.374-.53 2.503-.543zM12 10.941l-.116.006A1.057 1.057 0 0010.941 12v3.177c0 .588.474 1.058 1.059 1.058l.116-.006c.533-.057.943-.507.943-1.052V12c0-.589-.474-1.059-1.059-1.059zm0-3.176a1.06 1.06 0 100 2.12 1.06 1.06 0 000-2.121z" }, null, -1)
  ])))
}
export default { render: render }