import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "#28282D",
  "fill-rule": "evenodd"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.0007 2.82422C6.93266 2.82422 2.82422 6.93266 2.82422 12.0007C2.82422 17.0687 6.93266 21.1772 12.0007 21.1772C17.0687 21.1772 21.1772 17.0687 21.1772 12.0007C21.1772 6.93266 17.0687 2.82422 12.0007 2.82422ZM17.0232 9.91828C17.4381 9.50338 17.4381 8.89728 17.0232 8.48238C16.6428 8.10199 16.0983 8.06844 15.7042 8.37908L15.5729 8.49599L10.8166 13.2531L8.79024 11.2267C8.37534 10.8118 7.76924 10.8118 7.35435 11.2267C6.97395 11.6071 6.9404 12.1516 7.25104 12.5457L7.35435 12.6626L10.0987 15.4069C10.5666 15.8748 10.9974 15.8796 11.4315 15.5029L11.5346 15.4069L17.0232 9.91828Z" }, null, -1)
  ])))
}
export default { render: render }