import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "#28282D"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M15.168 4c1.22 0 1.924.14 2.66.544a4.026 4.026 0 011.642 1.684c.394.756.53 1.479.53 2.73v6.084c0 1.251-.136 1.974-.53 2.73a4.026 4.026 0 01-1.642 1.684c-.736.404-1.44.544-2.66.544H8.832c-1.22 0-1.924-.14-2.66-.544a4.026 4.026 0 01-1.642-1.684c-.375-.72-.516-1.41-.53-2.57V8.959c0-1.251.136-1.974.53-2.73a4.026 4.026 0 011.642-1.684c.7-.385 1.374-.53 2.503-.543Zm-3.367 10.611a.984.984 0 100 1.969.985.985 0 100-1.969Zm.204-6.708c-1.172 0-2.087.474-2.46 1.262a1.507 1.507 0 00-.172.708c0 .487.318.808.803.808.386 0 .665-.194.812-.583.158-.45.471-.677.942-.677.527 0 .88.323.88.793 0 .454-.182.695-.844 1.101l-.134.084c-.602.397-.883.85-.883 1.478v.077l.004.1.013.1c.076.422.384.69.824.69.425 0 .69-.24.785-.677.069-.453.233-.655.888-1.044.816-.493 1.206-1.107 1.206-1.959 0-1.353-1.08-2.26-2.664-2.26Z" }, null, -1)
  ])))
}
export default { render: render }