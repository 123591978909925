/**
 * @see src/CamBundle/Message/Websocket/Schedule/ScheduleStatus.php
 */

import * as t from 'io-ts';
import { Type } from './Type';
import { StatusC } from './Status';

export const ScheduleStatusC = t.type({
    type: t.literal(Type.STATUS),
    id: t.number,
    status: StatusC,
});

export type ScheduleStatus = t.TypeOf<typeof ScheduleStatusC>;
