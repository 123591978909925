import { VueElementConstructor } from 'vue';
import NotificationButton from './NotificationButton';
import { assertIsNonNullable } from '../../typeAssertions/undefined';
import { Variant } from '../../../vue/components/Elements/Button/Variant';
import NotificationCheckbox from './NotificationCheckbox';
import WarningIcon from '../../../images/warning.svg?component';

export const enum NotificationVariant {
    PRIMARY = 'primary',
    TITLE_WITH_MESSAGE = 'title_with_message',
}

export type NotificationProps = {
    text?: string,
    show?: boolean,
    warning?: string,
    icon?: VueElementConstructor|null,
    withCancel?: boolean,
    title?: string,
    variant?: NotificationVariant,
    cancelText?: string,
    cancelVariant?: Variant,
}

export default class Notification {
    public readonly text: string = 'Are you sure?';

    public readonly title: string = '';

    public readonly variant: NotificationVariant = NotificationVariant.PRIMARY;

    public readonly show: boolean = true;

    public readonly warning: string | null = null;

    public readonly icon: VueElementConstructor|null = WarningIcon;

    public actionButtons: NotificationButton[] = [];

    public cancelButton: NotificationButton | null = null;

    public checkbox: NotificationCheckbox | null = null;

    constructor({
        text, show, warning,
        icon, withCancel = true,
        title, variant,
        cancelText,
        cancelVariant,
    }: NotificationProps = {}) {
        if (title) {
            this.title = title;
        }

        if (variant) {
            this.variant = variant;
        }
        if (text !== undefined) {
            this.text = text;
        }

        if (show !== undefined) {
            this.show = show;
        }

        if (warning !== undefined) {
            this.warning = warning;
        }

        if (icon !== undefined) {
            this.icon = icon;
        }

        if (withCancel) {
            this.cancelButton = new NotificationButton(
                cancelText ?? 'Cancel',
                () => undefined,
                cancelVariant,
            );
        }
    }

    public addActionButton(notificationButton: NotificationButton): void {
        this.actionButtons.push(notificationButton);
    }

    public setCancelButtonText(text: string): void {
        assertIsNonNullable(this.cancelButton);
        this.cancelButton.text = text;
    }

    public addCheckbox(checkbox: NotificationCheckbox): void {
        this.checkbox = checkbox;
    }

    public getCheckboxState(): boolean {
        return this.checkbox ? this.checkbox.getCheckboxState() : false;
    }
}
