import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "#000000",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M4 16C4 14.8954 4.89543 14 6 14C7.10457 14 8 14.8954 8 16V18C8 19.1046 7.10457 20 6 20C4.89543 20 4 19.1046 4 18V16Z" }, null, -1),
    _createElementVNode("path", { d: "M10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6V18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18V6Z" }, null, -1),
    _createElementVNode("path", { d: "M16 11C16 9.89543 16.8954 9 18 9C19.1046 9 20 9.89543 20 11V18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18V11Z" }, null, -1)
  ])))
}
export default { render: render }