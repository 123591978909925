import * as t from 'io-ts';
import { UserGroupC } from './UserGroup';

export const UserC = t.type({
    id: t.number,
    username: t.string,
    isDeleted: t.boolean,
    showingName: t.string,
    group: t.union([UserGroupC, t.null, t.undefined]),
    groupIds: t.array(t.number),
});

export type User = t.TypeOf<typeof UserC>;
