import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 49 65",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M48.71 51.68H40.97V65H27.74V51.68H0.290039V42.23L28.46 0.73999H40.97V41.15H48.71V51.68ZM27.74 30.26C27.74 29.24 27.74 28.04 27.74 26.66C27.8 25.22 27.86 23.81 27.92 22.43C27.98 20.99 28.04 19.73 28.1 18.65C28.16 17.51 28.22 16.73 28.28 16.31H27.92C27.38 17.51 26.81 18.68 26.21 19.82C25.61 20.96 24.89 22.13 24.05 23.33L12.26 41.15H27.74V30.26Z" }, null, -1)
  ])))
}
export default { render: render }