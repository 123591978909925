import * as t from 'io-ts';
import { ReportCreatedC } from './ReportCreated';
import { ReportUpdatedC } from './ReportUpdated';
import { ReportDeletedC } from './ReportDeleted';

export const ReportMessageC = t.union([
    ReportCreatedC,
    ReportUpdatedC,
    ReportDeletedC,
]);

export { Type } from './Type';
export type ReportMessage = t.TypeOf<typeof ReportMessageC>;
