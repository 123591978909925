<template>
    <Button
        :id="id"
        :class="buttonClass"
        :variant="variant"
        :shadowed="shadowed"
        :cypress-id="cypressId"
        :size="size"
        :block="block"
        :has-only-icon="hasOnlyIcon"
        :disabled="disabled"
        :no-horizontal-padding="noHorizontalPadding"
        @click="onClick"
        @mouseover="onMouseOver"
    >
        <slot />

        <Dropdown
            :id="`dropdown-${id}`"
            v-model:show="show"
            :target="id"
            :placement="placement"
            :no-opacity="noOpacity"
            :boundary-padding="boundaryPadding"
            :container="container"
            :auto-fit="autoFit"
            :offset="offset"
            @shown="onShow"
            @hidden="onHidden"
        >
            <slot name="dropdown" />
        </Dropdown>
    </Button>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import Button from './Button.vue';
import { ButtonSize } from './Button/Size';
import { Variant } from './Button/Variant';
import Dropdown from './Dropdown.vue';
import { Position } from '../../store/modules/globalSettings';
import { HIDE_DROPDOWNS_EVENT } from '../../../scripts/constants/events';
import dropdownEventBus from '../../Event/DropdownEventBus';

type Props = {
    cypressId?: string| null,
    id: string,
    name?: string|null,
    hasOnlyIcon?: boolean,
    shadowed?: boolean,
    block?: boolean,
    size?: ButtonSize|string,
    variant?: Variant|string,
    placement?: Position,
    buttonClass?: string|null,
    noOpacity?: boolean,
    container?: string|null,
    disabled?: boolean,
    showDropdown?:boolean,
    boundaryPadding?: number,
    autoFit?: boolean,
    offset?: number,
    noHorizontalPadding?: boolean,
};

const props = withDefaults(defineProps<Props>(), {
    name: null,
    hasOnlyIcon: false,
    cypressId: null,
    shadowed: true,
    block: true,
    size: ButtonSize.MEDIUM,
    variant: Variant.WHITE,
    placement: Position.BOTTOM_RIGHT,
    buttonClass: null,
    noOpacity: false,
    container: null,
    disabled: false,
    boundaryPadding: 0,
    offset: undefined,
    noHorizontalPadding: false,
    styles: undefined,
});

type Emits = {
    (event: 'click', value: Event): void,
    (event: 'dropdownShow'): void,
    (event: 'dropdownHidden'): void,
};

const emit = defineEmits<Emits>();

const show = ref<boolean>(props.showDropdown);

const onClick = (event: Event) => {
    dropdownEventBus.emit(HIDE_DROPDOWNS_EVENT, props.id);
    show.value = !show.value;
    emit('click', event);
};

const onMouseOver = () => {
    dropdownEventBus.emit(HIDE_DROPDOWNS_EVENT, props.id);
};

const onShow = () => {
    emit('dropdownShow');
};

const onHidden = () => {
    emit('dropdownHidden');
};

watch(() => props.showDropdown, () => {
    show.value = props.showDropdown;
});

dropdownEventBus.on(HIDE_DROPDOWNS_EVENT, (id) => {
    if (show.value && id !== props.id) {
        show.value = false;
    }
});
</script>
